import React from 'react';
import * as hs from "@hlibs/react/hstyles"; 
// import MuiLoading from './Loading-MuiLoading';
import ReactLoading from './Loading-ReactLoading';

export const type = {
  ReactLoading: "ReactLoading",
//  MuiLoading: "MuiLoading"
};

export const defaultProps = {
  loadingType: type.ReactLoading
};

const Component = (props) =>
{
  const mergedProps = hs.mergeProps(defaultProps, props);

  const { loadingType } = mergedProps;

  switch(loadingType)
  {
    case 'ReactLoading': return <ReactLoading {...props}/>
    default: return <ReactLoading {...props}/>
    // default: return <MuiLoading {...props}/>
  }
};

export default Component;