import React from 'react';
import ReactLoading from 'react-loading';
import * as hs from "@hlibs/react/hstyles"; 
import { Box, Spacing, Text } from '@hlibs/react-web/components';

const defaultProps = {
    type: 'spin',
    color: '#ffffff',
    width: '50px',
    height: '50px',
    message: "",
    textSize: 20,
    textColor: '#333333',
    popupColor: '#ffffff00',
    visible: false
};

const Component = (props = {
    type: 'spin',
    color: '#ffffff'
}) =>
{
  const mergedProps = hs.mergeProps(defaultProps, props);

  const styles = mergedProps.style ? hs.mergeStyles(mergedProps.style) : {};
    
  const { type, color, width, height, message, textSize, textColor, popupColor } = mergedProps;

  return (
    <Box style={[hs.fixed.leftTop(), hs.size.fillParent(), hs.backgroundColor('#00000090'), hs.flex.verticalLayout(), hs.flex.align.vertical.center(), styles]}> 
      <Box style={[hs.padding.custom(60, 35, 60, 30), hs.radius.all(20, popupColor), hs.flex.verticalLayout(), hs.flex.align.vertical.center(), ]}>
        {message ? <Text size={textSize} style={[hs.color(textColor)]}><b>{message}</b><Spacing y={30}/></Text> : null}                    
        <ReactLoading type={type} color={color} height={width} width={height} /> 
      </Box>
    </Box>
  );
};

export default Component;