import React, { useEffect, useRef, useState } from "react";
import DefaultLayout from '@layout/DefaultLayout';
import { hs, Assets, Modal, Utils } from "@hlibs/index";
import { Box, Image, InputBox, Spacing, Text, Check, TextBox } from '@hlibs/react-web/components';
import { AlertDialog, QRCodeDialog } from "@modals/index";
import { colors } from '@app/index';
import { useSearchParams, createSearchParams, useNavigate } from "react-router-dom";
import reduxUser from '@redux/user';
import reduxAdmin from '@redux/admin';
import reduxKyc from '@redux/kyc';
import { useSelector } from "react-redux";
import Setting from './Setting';
import Language from '@app/language';
import { useKycResponse } from "@redux/socket/hook";

const TableRow = (props) =>
{
  const { data, style, isTitle, colWidth } = props;

  return (
    <Box style={[hs.flexLayout.horizontal(), hs.backgroundColor(colors.white), hs.size.width('96%'), hs.border.all('solid', colors.border2, 1), !isTitle ? hs.border.top('solid', colors.border2, 0) : null, style]}>
      {
        data.map((value, index) =>
        (
          isTitle ? (
            <Box key={index} style={[hs.size.width(colWidth[index]), hs.padding.custom(0, 15, 0, 15), hs.verticalLayout(), hs.verticalAlign.center(), index > 0 ? hs.border.left('solid', colors.border2, 0.5) : null, hs.backgroundColor(colors.border1)]}>
              {typeof value == 'string' ? <Text style={[hs.flex(), hs.text.medium(20, colors.black)]}>{value}</Text> : value}            
            </Box>
          ) : (
            <Box key={index} style={[hs.size.width(colWidth[index]), hs.flex(), hs.padding.custom(0, 7, 0, 7), hs.verticalLayout(), hs.verticalAlign.center()]}>
              {typeof value == 'string' ? <Text style={[hs.text.regular(18, colors.black)]}>{value}</Text> : value}            
            </Box>  
          )
        ))
      }
    </Box>
  );
};

const InputContainer = ({ buttonLabel, buttonTextColor, buttonBackgroundColor, onClick, style }) => 
{
  const inputRef = useRef();

  return (
    <Box style={[hs.size.height(54), hs.size.fillParentWidth(), hs.flexLayout.horizontal(), hs.outline(8, colors.border2, 2), hs.padding.left(10), style]}>
      <InputBox ref={inputRef} onSubmit={onClick ? () => onClick(inputRef.current.getValue()) : null} inputStyle={[hs.text.regular(20, colors.black)]} style={[hs.flex(), hs.size.fillParentHeight(), hs.padding.right(10)]}/>
      <Box onClick={onClick ? () => onClick(inputRef.current.getValue()) : null} style={[hs.size.width(152), hs.backgroundColor(buttonBackgroundColor), hs.size.fillParentHeight(), hs.flexLayout.horizontal(), hs.flexAlign.horizontal.center(), hs.radius.right(4)]}>
        <Text style={[hs.text.medium(22, buttonTextColor)]}>{buttonLabel}</Text>
      </Box>
    </Box>
  );
};

const TextButton = (props) =>
{
  const { label, onClick, bgColor, textColor, width } = props;

  return (
    <Box onClick={onClick} style={[hs.size.custom(width ? width : 114, 32), hs.flexLayout.horizontal(), hs.flexAlign.horizontal.center(), hs.radius.all(23, bgColor ? bgColor : colors.white), props.style]}>
      <Text style={[hs.text.medium(21, textColor ? textColor : colors.black)]}>{label}</Text>
    </Box>
  );
};



const pageNavItemCount = 10;

const ViewAdmin = () =>
{
  const kycResponse = useKycResponse();

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const [tabIndex, setTabIndex] = useState(0);

  const page = parseInt(searchParams.get('page') || 0);
  const keyword = searchParams.get('keyword') || "";

  const tableWidth = [ "15%", "20%", "20%", "25%", "10%", "10%"];

  const tableData = [
    [Language.ViewAdmin.id, Language.ViewAdmin.name, Language.ViewAdmin.phone, Language.ViewAdmin.email, "KYC", ""]
  ];

  const admin = useSelector((state) => state.Admin?.user);
  const userList = useSelector((state) => state.User.List);
  const needRefresh = useSelector((state) => state.User.NeedRefresh);
  const pageCount = userList?.totalRows && userList?.pageRows ? Math.ceil(userList?.totalRows / userList?.pageRows) : 0;

  useEffect(() =>
  {
    if(kycResponse)
    {
      const message = kycResponse?.result ? Language.Socket.msg1(kycResponse?.userInfo?.id) : Language.Socket.msg2(kycResponse?.userInfo?.id);

      reduxUser.set(true, 'NeedRefresh');
  
      AlertDialog.create().modal(() =>
      {
  
      }, message, AlertDialog.presetButtons.ok );  
    }

  }, [kycResponse]);
  
  const onSendKyc = (userItem) =>
  {
    reduxKyc.send(userItem.idx);
  };

  const onDeleteUser = (userItem) =>
  {
    AlertDialog.create().modal((buttonIndex) =>
    {
      if(buttonIndex == 1)
      {
        reduxUser.deleteUser(userItem.idx)
      }
    }, Language.ViewAdmin.msg1, AlertDialog.presetButtons.deleteCancel);
  };

  if(userList?.list)
  {
    for(let itemCur of userList?.list)
    {
      const isSent = Utils.moment(itemCur.expire_time).toDate() >= Utils.DateUtil.now() && itemCur.result == null ? true : false;

      tableData.push([itemCur.id, itemCur.name, itemCur.phone, itemCur.email, 
        <TextButton label={isSent ? 'Sent' : 'Send'} bgColor={isSent ? '' : colors.white} textColor={isSent ? '#F09F0B' : colors.primary} style={[isSent ? null : hs.outline(23, colors.primary, 1, colors.white)]} onClick={!isSent ? () => onSendKyc(itemCur) : null} />, 
        <TextButton label={'Delete'} textColor={colors.deleteRed} style={[hs.outline(23, colors.deleteRed, 1, colors.white)]} onClick={() => onDeleteUser(itemCur)} />]);
    }
  }

  const pageNavItems = [];

  const startPage = page - pageNavItemCount > 0 ? page - pageNavItemCount : 1;

  const lastPage = startPage + pageNavItemCount <= pageCount ? startPage + pageNavItemCount : pageCount;

  for(let i = startPage; i <= lastPage; i++) pageNavItems.push(i);

  useEffect(() => { reduxAdmin.login('tester', 'test1111'); }, []);
  useEffect(() => { if(admin) reduxUser.getUserList(page, keyword); }, [admin, page, keyword]);
  useEffect(() => { if(needRefresh && admin) { reduxUser.getUserList(page, keyword); } }, [needRefresh]);

  const onBtnPage = (pageIndex) =>
  {
    searchParams.set('page', pageIndex);

    navigate({
      search: createSearchParams(searchParams).toString(),
    }, {replace: true});
  };

  const onBtnSearch = (keyword) =>
  {
    if(!keyword)
    {
      searchParams.delete("keyword");
    }

    else
    {
      searchParams.set('keyword', keyword);
    }

    navigate({
      search: createSearchParams(searchParams).toString(),
    }, {replace: true});
  };

  const onPrevPage = () =>
  {
    searchParams.set('page', page - 1);

    navigate({
      search: createSearchParams(searchParams).toString(),
    }, {replace: true});
  };

  const onNextPage = () =>
  {
    searchParams.set('page', page + 1);

    navigate({
      search: createSearchParams(searchParams).toString(),
    }, {replace: true});
  };  

  return (
    <DefaultLayout style={[hs.backgroundColor(colors.bg1)]}>
      <Spacing y={20}/>
      {
        tabIndex == 0 ? (
          <>
            <Box style={[hs.flexLayout.horizontal(), hs.size.fillParentWidth(), hs.border.bottom('solid', colors.header, 1), hs.size.height(50), hs.padding.bottom(20)]}>
              <Box style={[hs.flex(1), hs.flexLayout.horizontal(), hs.flexAlign.horizontal.leftMiddle()]}>                
                <Box style={[hs.flexLayout.horizontal(), hs.flexAlign.horizontal.center(), hs.size.width(200)]} onClick={() => setTabIndex(0)}>
                  <Text style={[tabIndex == 0 ? hs.text.bold(30, colors.primary) : hs.text.bold(28, colors.grey)]}>User List</Text>
                </Box>
                <Box style={[hs.flexLayout.horizontal(), hs.flexAlign.horizontal.center(), hs.size.width(200)]} onClick={() => setTabIndex(1)}>
                  <Text style={[tabIndex == 1 ? hs.text.bold(30, colors.primary) : hs.text.bold(28, colors.grey)]}>Setting</Text>
                </Box>
              </Box>
              <Box style={[hs.flex(1), hs.padding.right(35)]}>
                <Box style={[hs.flexLayout.horizontal(), hs.size.fillParentWidth(), hs.flexAlign.horizontal.rightMiddle()]}>
                  <InputContainer buttonBackgroundColor={'#3f3f3f'} buttonTextColor={colors.yellow} buttonLabel={Language.ViewAdmin.label1} onClick={(keyword) => { onBtnSearch(keyword); }} style={[hs.size.width(500)]}/>
                </Box>
              </Box>
            </Box>
            <Spacing y={20}/>
            <Box style={[hs.flexLayout.horizontal(true), hs.flexAlign.horizontal.center(), hs.size.fillParentWidth(), hs.padding.leftRightAll(80)]}>
              {
                tableData.map((row, index) => (
                  <TableRow key={index} data={row} colWidth={tableWidth} isTitle={index == 0 ? true : false}/>
                ))
              }
            </Box>
            <Spacing y={20}/>
            <Box style={[hs.flexLayout.horizontal(), hs.horizontalAlign.center(), hs.size.fillParentWidth(), hs.padding.bottom(20)]}>
              <TextButton label={'<'} width={40} bgColor={colors.white} textColor={page + 1 > 1 ? colors.primary : colors.border2} style={[hs.margin.left(14)]} onClick={page + 1 > 1 ? onPrevPage : null} />
              {
                pageNavItems.length > 0 ? (
                  pageNavItems.map((pageItem, index) => 
                  (
                    <TextBox key={index} style={[page == index ? hs.text.medium(24, colors.primary) : hs.text.regular(22, colors.grey), hs.margin.left(14)]} onClick={page == index ? null : () => onBtnPage(index)} >{pageItem}</TextBox>
                  ))
                ) : null
              }
              <TextButton label={'>'} width={40} bgColor={colors.white} textColor={page + 1 < pageCount ? colors.primary : colors.border2} style={[hs.margin.left(14)]} onClick={page + 1 < pageCount ? onNextPage : null} />
            </Box>          
          </>
        ) : (
          <>
            <Box style={[hs.flexLayout.horizontal(), hs.size.fillParentWidth(), hs.border.bottom('solid', colors.header, 1), hs.size.height(50), hs.padding.bottom(20)]}>
              <Box style={[hs.flex(1), hs.flexLayout.horizontal(), hs.flexAlign.horizontal.leftMiddle()]}>                     
                <Box style={[hs.flexLayout.horizontal(), hs.flexAlign.horizontal.center(), hs.size.width(200)]} onClick={() => setTabIndex(0)}>
                  <Text style={[tabIndex == 0 ? hs.text.bold(30, colors.primary) : hs.text.bold(28, colors.grey)]}>User List</Text>
                </Box>
                <Box style={[hs.flexLayout.horizontal(), hs.flexAlign.horizontal.center(), hs.size.width(200)]} onClick={() => setTabIndex(1)}>
                  <Text style={[tabIndex == 1 ? hs.text.bold(30, colors.primary) : hs.text.bold(28, colors.grey)]}>Setting</Text>
                </Box>
              </Box>
              <Spacing flex={1}/>
            </Box>          
            <Setting />
          </>          
        )
      }
      <Spacing y={20}/>
    </DefaultLayout>
  );
};

export default ViewAdmin;