import React, { useEffect } from "react";
import DefaultLayout from '@layout/DefaultLayout';
import { hs, Assets } from "@hlibs/index";
import { Box, Image, Text, Spacing, TextBox } from '@hlibs/react-web/components';
import { colors } from '@app/index';
import { useSelector } from "react-redux";

const ViewMain = () =>
{
  const user = useSelector((state) => state.Account.user);

  const lastVerifiedOtac = localStorage.getItem("lastVerifiedOtac");

  const welcomeMessage = lastVerifiedOtac == "1" && user?.id && user?.id != 'tester' ? `Hello, ${user?.id}!` : 'Hello!';

  return (
    <DefaultLayout style={[hs.verticalLayout(), hs.verticalAlign.center()]}>
      <Spacing flex={1}/>
      <Box style={[hs.size.width(1096), hs.size.height(39), hs.horizontalLayout(), hs.horizontalAlign.leftMiddle()]}>
        <Spacing x={20}/>
        <Image src={Assets.fromImage.svg('main/icn_alram_on')} width={32} height={39}/>
        <Spacing x={20}/>
        <Text style={[hs.text.bold(48, '#3f3f3f', hs.flex(), hs.flexItems.alignSelf.stretch())]}>{welcomeMessage}</Text>
      </Box>
      <Spacing y={30}/>
      <Box style={[hs.size.width(1096), hs.shadow(), hs.radius.all(8, colors.yellow)]}>
        <Spacing y={42}/>
        <Box style={[hs.horizontalLayout(), hs.padding.leftRightAll(35), hs.horizontalAlign.leftMiddle()]}>
          <Image src={Assets.fromImage.svg('main/icn_swidch_code')} width={56} height={56}/>
          <Spacing x={15}/>
          <Text style={[hs.text.bold(29, colors.white)]}>{'ACCOUNT WATCH'}</Text>
          <Box style={[hs.horizontalLayout(), hs.horizontalAlign.rightBottom(), hs.flex(1), hs.flexItems.alignSelf.stretch()]}>
            <Image src={Assets.fromImage.svg('main/icn_info')} width={20} height={20}/>
            <Spacing x={8}/>
            <Text style={[hs.text.regular(16, '#3f3f3f')]}>{'Balance Definitions'}</Text>
          </Box>
        </Box>
        <Spacing y={22} style={[hs.size.fillParentWidth(), hs.border.bottom('solid', '#ffe79f', 1)]}/>
        <Box style={[hs.verticalLayout(), hs.padding.leftRightAll(108), hs.verticalAlign.center()]}>
          <Spacing y={50}/>
          <Box style={[hs.horizontalLayout(), hs.horizontalAlign.leftBottom(), hs.size.fillParentWidth()]}>
            <Text style={[hs.text.bold(24, '#3f3f3f')]}>{'Deposit and Sweep Account'}</Text>
            <Spacing flex={1}/>
            <Text style={[hs.text.regular(16, '#3f3f3f')]}>{'Prior day Balance'}</Text>
            <Spacing x={37}/>
            <Text style={[hs.text.regular(16, '#3f3f3f')]}>{'Current Balance'}</Text>
            <Spacing x={6}/>
            <Image src={Assets.fromImage.svg('main/icn_check')} width={20} height={20}/>
          </Box>
          <Spacing y={18}/>
          <Box style={[hs.size.fillParentWidth(), hs.size.height(121), hs.radius.all(8, colors.white), hs.verticalLayout()]}>
            <Box style={[hs.flex(), hs.horizontalLayout(), hs.padding.leftRightAll(35), hs.horizontalAlign.leftMiddle()]}>
              <Text style={[hs.text.regular(18, '#3f3f3f')]}>{'OPERATING ACCOUNT(*** 3149)'}</Text>
            </Box>
            <Spacing y={1} style={[hs.backgroundColor(colors.footer + '55')]}/>
            <Box style={[hs.flex(), hs.horizontalLayout(), hs.padding.leftRightAll(35), hs.horizontalAlign.leftMiddle()]}>
              <Text style={[hs.text.regular(18, '#3f3f3f')]}>{'CORPORATE DEPOSIT ACCOUNT(*** 3149)'}</Text>
            </Box>
          </Box>
          <Spacing y={94}/>
        </Box>
      </Box>
      <Spacing flex={1}/>
    </DefaultLayout>
  );
};

export default ViewMain;
