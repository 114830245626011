let _handlerKeyIndexPos = 1;

export default class Handler 
{
    #_handlerID = null;
    #_handlerIndexPos = 0;
    #_handlers = [];

    static create()
    {
        return new Handler();
    }
    
    constructor()
    {
        this.#_handlerID = "Handler_" + _handlerKeyIndexPos++;
    }

    get length()
    {
        return this.#_handlers.length;
    }

    add = (handler) =>
    {
        let id = this.#_handlerID + "_" + this.#_handlerIndexPos++;
        
        this.#_handlers.push({ id, handler });

        return () =>
        {
            this.remove(id);
        };
    };

    remove = (handlerID) =>
    {
        for(let i = 0; i < this.#_handlers.length; i++)
        {
            if(this.#_handlers[i].id == handlerID)
            {
                this.#_handlers.splice(i, 1);

                break;
            }
        }
    };

    clear = () =>
    {
        this.#_handlers = [];
    };

    // stopWhen : 콜백 스택에 등록된 콜백들을 실행하다가 실행한 콜백이 stopWhen.handlerReturn 에 지정한 값을 반환한 경우 returnVal 을 리턴하며 invoke 중단.
    // ex) stopWhen = { use: true, handlerReturn: [true], returnVal: false }
    invoke = (params, stopWhen = null) =>
    {
        for(let i = this.#_handlers.length - 1; i >= 0; i--)
        {
            let handlerCur = this.#_handlers[i];

            if(handlerCur.handler)
            {
                let result = handlerCur.handler(params);
                
                if(stopWhen?.use)
                {
                    if(Array.isArray(stopWhen.handlerReturn))
                    {
                        for(let itemCur of stopWhen.handlerReturn)
                        {
                            if(result == itemCur)
                            {
                                return stopWhen.returnVal;
                            }
                        }
                    }

                    else
                    {
                        if(result == stopWhen.handlerReturn)
                        {
                            return stopWhen.returnVal;
                        }
                    }
                }
            }
        }  

        return null;
    };
}
