import * as hs from "@hlibs/react/hstyles"; 

const Spacing = (props = {
    x: null,
    y: null,

    width: null,
    height: null,

    flex: null,
    flexGrow: null,
    flexShrink: null,   
}) =>
{
    const { x, y, width, height, flex, flexGrow, flexShrink } = props;

    const widthCur = width || x;
    const heightCur = height || y;

    const flexCur = flex || flexGrow;
    const flexGrowCur = flexGrow || flexCur;
    const flexShrinkCur = flexShrink || flexCur;

    const styles = [widthCur ? hs.size.width(widthCur) : null, heightCur ? hs.size.height(heightCur) : null, flexCur ? hs.flex(flexCur, flexGrowCur, flexShrinkCur) : null, props.style];

    return (
        <div style={hs.mergeStyles(styles)}/>
    )
};

export default Spacing;