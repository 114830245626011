import { ReduxSagaRequest, Utils, Debug, Request } from '@hlibs/index';
import Language from '@app/language';
import { Toast } from '@hlibs/index';

class User extends ReduxSagaRequest
{
    static create = (name, funcAsync = null, funcResult = null) => new User(name, funcAsync, funcResult);

    getUserList(page = 0, keyword = "")
    {
        super.request({ type: 'getUserList', page, keyword });
    }

    deleteUser(user_idx)
    {
        super.request({ type: 'deleteUser', user_idx });
    }
};

const reduxModule = User.create("User", async (payload) =>
{
    switch(payload.type)
    {
        case 'getUserList': return await getUserList(payload);
        case 'deleteUser': return await deleteUser(payload);
        default: return null;
    }
});

const getUserList = async (payload) =>
{    
    const stateKey = 'List';

    if(Utils.checkNullOrEmpty(payload)) return ReduxSagaRequest.makeResult.failureWithMessage(Language.Server.err3, stateKey);

    try
    {
        const { data } = await Request.get('/user/list', payload);

        if(data?.error)
        {
            return ReduxSagaRequest.makeResult.failure(data, stateKey);
        }

        else if(Utils.checkKeyExists(data, ['list']))
        {
            reduxModule.set(null, 'NeedRefresh');

            return ReduxSagaRequest.makeResult.success(data, stateKey);
        }    
    }
    catch(e)
    {
        if(process.env.MODE !== 'RELEASE') Debug.exception(e);
    }

    return ReduxSagaRequest.makeResult.failureWithMessage(Language.Server.err1, stateKey);
}; 

const deleteUser = async (payload) =>
{
    const stateKey = 'Delete';

    if(Utils.checkNullOrEmpty(payload, payload.user_idx)) return ReduxSagaRequest.makeResult.failureWithMessage(Language.Server.err3, stateKey);

    try
    {
        const { data } = await Request.post('/user/delete', payload);

        if(data?.error)
        {
            return ReduxSagaRequest.makeResult.failure(data, stateKey);
        }

        else if(Utils.checkKeyExists(data, ['result']))
        {
            if(data.result == true)
            {
                Toast.show(Language.USER.msg1);
            }

            else
            {
                Toast.show(Language.USER.msg2);
            }
            
            return ReduxSagaRequest.makeResult.success(true, 'NeedRefresh');
        }    
    }
    catch(e)
    {
        if(process.env.MODE !== 'RELEASE') Debug.exception(e);
    }

    return ReduxSagaRequest.makeResult.failureWithMessage(Language.Server.err1, stateKey);
}; 

export default reduxModule;