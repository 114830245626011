import React from 'react';
import PropsDivider from '@hlibs/react/props-divider';
import * as hs from "@hlibs/react/hstyles"; 
import { useState } from 'react';

const defaultProps = 
{

};

const defaultStyles = {
  
};

const Component = (props = defaultProps) =>
{
    const mergedProps = hs.mergeProps(defaultProps, props);

    const styles = hs.mergeStyles(defaultStyles, mergedProps.style);

    const [opacity, setOpacity] = useState(1);
    
    if(mergedProps.onPress && !mergedProps.onClick)
    {
        mergedProps.onClick = mergedProps.onPress;

        delete mergedProps.onPress;
    }

    // 마우스 클릭시 opacity * 0.5 로 적용되도록 처리 => 버튼용도로 사용 시
    if(mergedProps.onClick)
    {
        const onMouseDown = mergedProps.onMouseDown;
        const onMouseUp = mergedProps.onMouseUp;
        const onMouseLeave = mergedProps.onMouseLeave;
        
        mergedProps.onMouseDown = (event) => { setOpacity(0.5); if(onMouseDown) onMouseDown(event); };
        mergedProps.onMouseUp = (event) => { setOpacity(1); if(onMouseUp) onMouseUp(event); };
        mergedProps.onMouseLeave = (event) => { setOpacity(1); if(onMouseLeave) onMouseLeave(event); };

        styles.cursor = "pointer";
        styles.userSelect = "none";
    }

    styles.opacity = styles.opacity ? styles.opacity * opacity : opacity;

    // div props 분리
    const divProps = PropsDivider.div(mergedProps);

    return (
        <div {...divProps} style={styles}/>
    )
};

export default Component;