import ReduxSagaRequest from './redux-saga-request';
import SocketIoClient from '@hlibs/socket.io/client';

export default class ReduxSagaSocket extends ReduxSagaRequest
{
    static create = (name, address, authKey, config = { reconnect: true, transports: ['websocket'] }, funcAsync = null, funcResult = null) => new ReduxSagaSocket(name, address, authKey, config, funcAsync, funcResult);

    #_socket = null;

    #_onConnect = null;

    get socket() { return this.#_socket; }

    constructor(name, address, authKey, config = { reconnect: true, transports: ['websocket'] }, onConnect = null, funcAsync = null, funcResult = null)
    {
        super(name, funcAsync, funcResult);

        if(address)
        {
            this.connect(address, authKey, config);
        }

        this.#_onConnect = onConnect;
    }

    connect(address, authKey, config = { reconnect: true, transports: ['websocket'] })
    {
        const socket = SocketIoClient.connect(address, config);

        socket.on("connect", () =>
        {
            if(authKey)
            {
                socket.on('authenticate_response', (recvData) =>
                {
                    try
                    {
                        const data = JSON.parse(recvData);

                        if(data?.result == true)
                        {
                            super.set(true, 'connected');

                            if(this.#_onConnect)
                            {
                                this.#_onConnect(socket);
                            }
                        }
    
                        else
                        {
                            if(socket?.io?.reconnection())
                            {
                                socket?.io?.reconnection(false);
                            }
                        }    
                    }
                    catch(e)
                    {
//                        Debug.error(e);
                    }
                });

                socket.emit('authenticate', authKey);
            }

            else
            {
                super.set(true, 'connected');
            }
        });

        socket.on("disconnect", () =>
        {
//            console.log('disconnect');

            super.set(false, 'connected');
        });

        socket.on("connect_error", (error) =>
        {
//            console.log('connect_error');

            super.set(error, 'error');
        });

        this.#_socket = socket;
    }

    isConnected()
    {
        return super.getState('connected');
    }
};