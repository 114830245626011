import { useState, useEffect } from "react";

export const useWindowSize = (minWidth = null, maxWidth = null, minHeight = null, maxHeight = null) =>
{
  const isClient = typeof window === 'object';

  const getSize = () =>
  {
    return {
      width: isClient ? window.innerWidth : null,
      height: isClient ? window.innerHeight : null
    };
  };

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => 
  {
    if (!isClient) 
    {
      return false;
    }

    const handleResize = () =>
    {
      const size = getSize();

      let update = true;

      do
      {
        if(maxWidth && size.width > maxWidth) { update = false; break; }
        if(minWidth && size.width < minWidth) { update = false; break; }
        if(maxHeight && size.height > maxHeight) { update = false; break; }
        if(minHeight && size.height < minHeight) { update = false; break; }                
      } while(false)

      if(update)
      {
        setWindowSize(size);
      }
    };

    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []); 

  return windowSize;
}