import React from 'react';

export const isReactNative = false;

export const devScreenWidth = 1920;
export const devScreenHeight = 1080;

export const publishScreenWidth = 1680;

export const isIos = false;
export const isAndroid = false;
export const isWeb = true;

export const screenHeight = null;
export const screenWidth = null;

export const statusBarHeight = null;

export const scaleFactor = publishScreenWidth ? publishScreenWidth / devScreenWidth : 1;

export const getStatusBarHeight = () => null;


let _uniqueKeyCur = 0;

export const uniqueKey = () => { return 'uk-' + (++_uniqueKeyCur); };

// 폰 사이즈 체크 후 배율에 맞게 확대/축소 => 리액트에서는 scale 안함
export const scale = (size, mul = 1, mulScaleFactor = true, reverseScaleFactor = false) =>
{
    if(!mul && scaleFactor == 1) return size;

    if(size == null)
        return null;

    if(typeof size == 'string')
        return size;

    const newSize = reverseScaleFactor ? size * mul / (mulScaleFactor ? scaleFactor : 1) : size * mul * (mulScaleFactor ? scaleFactor : 1);

    return Math.round(newSize);
};

// 스타일 합치기. 동일한 키의 스타일을 정의한 경우, 뒤쪽에 정의한 스타일로 덮어씌움.
// ex) mergeStyle(styleA, styleB, styleC)
// ex) mergeStyle([styleA, styleB], styleC)
export const mergeStyles = (...styles) =>
{
    let styleResult = {};

    for(let styleCur of styles)
    {
        if(styleCur == null)
            continue;
            
        if(Array.isArray(styleCur))
        {
            styleCur = mergeStyles(...styleCur);
        }

        styleResult = { ...styleResult, ...styleCur };
    }
    
    return styleResult;
};

// Text 컴포넌트 생성
export const createTexts = (texts = [
    "text1",
    {
        text: "text2",
        style: null
    },
    {
        text: "text3",
        color: '#999999'
    },
], defaultStyle = null, multiLine = false) =>
{
    let components = [];

    for(let itemCur of texts)
    {
        let textCur = typeof itemCur == 'object' && itemCur.text ? itemCur.text : itemCur;

        let styleCur = mergeStyles(typeof itemCur == 'object' && itemCur.style ? itemCur.style : null, defaultStyle, 
            typeof itemCur == 'object' && itemCur.color ? { color: itemCur.color } : null);

        if(textCur)
        {
            components.push(
                <span key={uniqueKey()} style={styleCur}>{textCur}</span>
            );    
        }
    }

    return multiLine ? components : (<span style={defaultStyle}>{components}</span>);
};

// shadow
export const shadow = (boxShadow = "2.8px 2.8px 2.8px 0 rgba(4, 0, 0, 0.2)") => { return { boxShadow }; };

export const sizeExt = {};

export const display = 
{
    none: () => { return { display: 'none' }; }, 
    flex: () => { return { display: 'flex' }; },
    inlineFlex: () => { return { display: 'inline-flex' }; },
    block: () => { return { display: 'block' }; }, 
    inlineBlock: () => { return { display: 'inline-block' }; }, 
};

export const cursor = 
{
    default: () => { return { cursor: "default" }; },
    auto: () => { return { cursor: "auto" }; },
    pointer: () => { return { cursor: "pointer" }; },
    contextMenu: () => { return { cursor: "context-menu" }; },
    help: () => { return { cursor: "help" }; },
    progress: () => { return { cursor: "progress" }; },
    wait: () => { return { cursor: "wait" }; },
    cell: () => { return { cursor: "cell" }; },
    crosshair: () => { return { cursor: "crosshair" }; },
    text: () => { return { cursor: "text" }; },
    verticalText: () => { return { cursor: "vertical-text" }; },
    alias: () => { return { cursor: "alias" }; },
    copy: () => { return { cursor: "copy" }; },
    move: () => { return { cursor: "move" }; },
    noDrop: () => { return { cursor: "no-drop" }; },
    notAllowed: () => { return { cursor: "not-allowed" }; },
    grab: () => { return { cursor: "grab" }; },
    grabbing: () => { return { cursor: "grabbing" }; },
    allScroll: () => { return { cursor: "all-scroll" }; },
    colResize: () => { return { cursor: "col-resize" }; },
    rowResize: () => { return { cursor: "row-resize" }; },
    nResize: () => { return { cursor: "n-resize" }; },
    eResize: () => { return { cursor: "e-resize" }; },
    sResize: () => { return { cursor: "s-resize" }; },
    wResize: () => { return { cursor: "w-resize" }; },
    neResize: () => { return { cursor: "ne-resize" }; },
    nwResize: () => { return { cursor: "nw-resize" }; },
    seResize: () => { return { cursor: "se-resize" }; },
    swResize: () => { return { cursor: "sw-resize" }; },
    ewResize: () => { return { cursor: "ew-resize" }; },
    nsResize: () => { return { cursor: "ns-resize" }; },
    neswResize: () => { return { cursor: "nesw-resize" }; },
    nwseResize: () => { return { cursor: "nwse-resize" }; },
    zoomIn: () => { return { cursor: "zoom-in" }; },
    zoomOut: () => { return { cursor: "zoom-out" }; },    
};

export const userSelect = 
{
    none: () => { return { userSelect: "none" }; },
    all: () => { return { userSelect: "all" }; },
    text: () => { return { userSelect: "text" }; },
};

export const pointerEvents =
{
    // https://developer.mozilla.org/ko/docs/Web/CSS/pointer-events

    none: () => { return { pointerEvents: "none" }; },
    auto: () => { return { pointerEvents: "auto" }; },
    text: () => { return { pointerEvents: "text" }; },
};
