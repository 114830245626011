import { combineReducers, applyMiddleware, createStore } from 'redux';
import { spawn } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import ThunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from "redux-logger";
import ReduxData from './redux-data';
import ReduxThunkRequest from './redux-thunk-request';
import ReduxSagaRequest from './redux-saga-request';
import ReduxSagaSocket from './redux-saga-socket';
import * as Config from '@hlibs/config';
import modalReduxModule from '@hlibs/react/modal/redux';

const USE_REDUX_LOGGER = false;

export { ReduxData, ReduxThunkRequest, ReduxSagaRequest, ReduxSagaSocket };

export default class Redux
{
    static #_store = null;

    static createStore(reduxModules = null, reducers = null, sagas = null)
    {
        if(!reduxModules && !reducers)
        {
            throw new Error('reduxModules 혹은 reducers 를 지정해주세요');
        }

        if(this.#_store)
        {
            console.log('redux 규칙 상 store 는 하나만 생성 가능합니다.');
        }

        let thunkCount = 0;

        const _reduxModules = [];
        const _reducers = reducers ? { ...reducers } : {};
        const _sagas = sagas ? { ...sagas } : {};

        const pushReduxModule = (module) =>
        {
            if(module.reducer) _reducers[module.name] = module.reducer;

            if(module.saga) _sagas[module.name] = module.saga;

            if(module.thunk) thunkCount++;

            _reduxModules.push(module);
        };

        // modals
        pushReduxModule(modalReduxModule);

        if(reduxModules)
        {
            if(Array.isArray(reduxModules))
            {
                for(let itemCur of reduxModules)
                {
                    pushReduxModule(itemCur);
                }    
            }

            else if(typeof reduxModules == 'object')
            {
                for(let keyCur in reduxModules)
                {
                    let itemCur = reduxModules[keyCur];
    
                    pushReduxModule(itemCur);
                }    
            }
        }

        let reducerCount = Object.keys(_reducers).length;
        
        let sagaCount = Object.keys(_sagas).length;

        const rootReducer = reducerCount > 0 ? combineReducers(_reducers) : null;
        
        const rootSaga = sagaCount > 0 ? function* () 
        {
            for(let keyCur in _sagas)
            {
                yield spawn(_sagas[keyCur]);
            }
        } : null;
        
        const loggerMiddleware = Config.MODE !== 'RELEASE' ? createLogger() : null;
        const sagaMiddleware = sagaCount > 0 ? createSagaMiddleware() : null;
        const thunkMiddleware = thunkCount > 0 ? ThunkMiddleware : null;
        
        const middlewares = [];

        if(USE_REDUX_LOGGER && loggerMiddleware) middlewares.push(loggerMiddleware);
        if(sagaCount > 0) middlewares.push(sagaMiddleware);
        if(thunkCount > 0) middlewares.push(thunkMiddleware);

        const store = createStore(
            rootReducer,
            Config.MODE === 'RELEASE' ? applyMiddleware(...middlewares) : composeWithDevTools(applyMiddleware(...middlewares)),
        );

        if(sagaMiddleware && rootSaga)
        {
            sagaMiddleware.run(rootSaga);
        }
        
        for(let itemCur of _reduxModules)
        {
            if(itemCur.setStore)
            {
                itemCur.setStore(store);
            }
        }

        this.#_store = store;

        return store;
    };

    static get store() { return this.#_store; };
};

export const getStore = () => Redux.store;

export const getState = (name = null) => 
{
    const store = getStore();

    return name ? store[name].getState() : store.getState();
};

export const dispatch = (...args) =>
{
    return Redux.store.dispatch(...args);
};