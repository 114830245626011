import * as hs from "@hlibs/react/hstyles"; 
import Box from './Box';

// https://developer.mozilla.org/ko/docs/Web/CSS/background-image

// backgroundSize : auto, cover, contain, '200px, 100px'
const defaultProps = {
    width: null,
    height: null,

    backgroundImage: null,
    backgroundSize: 'cover',
};

const ImageBackground = (props = defaultProps) =>
{
    const mergedProps = hs.mergeProps(defaultProps, props);

    const styles = mergedProps.style ? hs.mergeStyles(mergedProps.style) : {};

    const backgroundImage = mergedProps.backgroundImage || mergedProps.source || mergedProps.src;

    const { width, height, backgroundSize, children } = mergedProps;

    return (
        <Box {...mergedProps} style={[hs.backgroundImage(backgroundImage), hs.backgroundSize(backgroundSize), width ? hs.size.width(width) : null, height ? hs.size.height(height) : null, styles]}>
            {children}
        </Box>
    )
};

export default ImageBackground;