import io from 'socket.io-client';
import handler from './client.handler';

export default class SocketIoClient
{
    static Handler = handler;

    static connect(address, config = { reconnect: true, transports: ['websocket'] })
    {
        return io.connect(address, config);
    }
}
