// https://socket.io/docs/v4/client-socket-instance/

export default class SocketIoClientHandler
{
    #_socket = null;

    constructor(socket)
    {
        this.#_socket = socket;
    }

    static Create = (socket) => new this(socket);

    // connect callback = () => {}
    connect = (callback) => this.#_socket.on("connect", callback);

    // disconnect callback = () => {}
    disconnect = (callback) => this.#_socket.on("disconnect", callback);

    // connect_error callback = (error) => {}
    connectError = (callback) => this.#_socket.on("connect_error", callback);
}
