import { ReduxSagaRequest, Utils, Debug, Request } from '@hlibs/index';
import Language from '@app/language';
import { Toast } from '@hlibs/index';
import reduxUser from '../user';

class KYC extends ReduxSagaRequest
{
    static create = (name, funcAsync = null, funcResult = null) => new KYC(name, funcAsync, funcResult);

    send(user_idx)
    {
        super.request({ type: 'send', user_idx });
    }
};

const reduxModule = KYC.create("KYC", async (payload) =>
{
    switch(payload.type)
    {
        case 'send': return await sendKyc(payload);
        default: return null;
    }
});

const sendKyc = async (payload) =>
{
    const stateKey = 'Send';

    if(Utils.checkNullOrEmpty(payload, payload.user_idx)) return ReduxSagaRequest.makeResult.failureWithMessage(Language.Server.err3, stateKey);

    try
    {
        const { data } = await Request.post('/kyc/send', payload);

        if(data?.error)
        {
            return ReduxSagaRequest.makeResult.failure(data, stateKey);
        }

        else if(Utils.checkKeyExists(data, ['result']))
        {
            if(data.result == true)
            {
                reduxUser.set(true, 'NeedRefresh');

                Toast.show(Language.KYC.msg1);
            }

            else
            {
                Toast.show(Language.KYC.msg2);
            }
            
            return ReduxSagaRequest.makeResult.success(data, stateKey);
        }    
    }
    catch(e)
    {
        Debug.exception(e);
    }

    return ReduxSagaRequest.makeResult.failureWithMessage(Language.Server.err1, stateKey);
}; 

export default reduxModule;