import React, { useEffect, useRef, useState } from "react";
import { hs, Assets, Modal, Utils } from "@hlibs/index";
import { Box, Image, InputBox, Spacing, Text, Check, TextBox } from '@hlibs/react-web/components';
import { colors } from '@app/index';
import reduxConfig from '@redux/config';
import { useSelector } from "react-redux";
import Language from '@app/language';

const Setting = (props) =>
{
  const settings = useSelector((state) => state.Config?.configs?.result);

  const allow1stOtac = settings?.ALLOW_1ST_OTAC;

  useEffect(() =>
  {
    reduxConfig.getSettings();
  }, []);

  return (
    <Box style={[hs.flexLayout.vertical(), hs.size.fillParentWidth(), hs.flexAlign.vertical.leftTop(), props.style]}>      
      <Box style={[hs.flex(), hs.padding.all(40)]}>
        <Text style={[hs.text.medium(30, colors.black)]}>{Language.ViewAdmin.Setting.label1}</Text>
        <Spacing y={20}/>
        <Box style={[hs.flex(), hs.horizontalLayout(), hs.horizontalAlign.leftMiddle()]}>
          <Text style={[hs.text.regular(26, colors.black)]}>{Language.ViewAdmin.Setting.label2}</Text>
          {
            allow1stOtac == 'Y' || allow1stOtac == 'N' ? (
              <Check iconWidth={97} iconHeight={39} iconOn={Assets.fromImage.png('home/setting/btn_on')} iconOff={Assets.fromImage.png('home/setting/btn_off')} 
                defaultValue={allow1stOtac == 'Y' ? true : false} style={[hs.margin.left(20), hs.size.height(39)]} onChange={(on) => { reduxConfig.set('ALLOW_1ST_OTAC', on ? 'Y' : 'N') }}/>
            ) : null
          }
        </Box>
      </Box>
    </Box>
  );
};

export default Setting;