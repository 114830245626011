import React from "react";
import * as hs from "@hlibs/react/hstyles"; 
import { Box, Spacing } from '@hlibs/react-web/components';

const defaultProps = 
{
  minWidth: null,
  minHeight: null,

  paddingLeftFlex: null,
  paddingRightFlex: null,
  paddingLeftRightFlex: null,

  containerStyles: null,

  bgColor: '#414143',
};

const defaultContainerStyle = hs.mergeStyles(
  hs.flexLayout.horizontal(), 
  hs.flexAlign.horizontal.middle()
);

const defaultStyles = hs.mergeStyles(
  hs.flexLayout.horizontal(true), 
  hs.flexAlign.horizontal.rightMiddle()
);

const Header = (props = defaultProps) =>
{
  const mergedProps = hs.mergeProps(defaultProps, props);

  const styles = hs.mergeStyles(defaultStyles, mergedProps.style);

  const containerStyles = hs.mergeStyles(defaultContainerStyle, mergedProps.containerStyle);

  const { logo, children, minWidth, minHeight, bgColor, paddingLeftFlex, paddingRightFlex, paddingLeftRightFlex } = mergedProps;

  const paddingLeftFlexCur = paddingLeftFlex || paddingLeftRightFlex;
  const paddingRightFlexCur = paddingRightFlex || paddingLeftRightFlex;

  return (
    <Box style={[hs.backgroundColor(bgColor), hs.size.fillParentWidth(), minWidth ? hs.size.minWidth(minWidth) : null, minHeight ? hs.size.minHeight(minHeight) : null, containerStyles]}>
      {paddingLeftFlexCur ? <Spacing flex={paddingLeftFlexCur} flexShrink={0}/> : null}
      {logo}
      {logo ? <Spacing flex={1}/> : null}
      <Box style={[hs.flex(), hs.flexLayout.horizontal(true), hs.flexAlign.horizontal.rightMiddle(), styles]}>{children}</Box>
      {paddingRightFlexCur ? <Spacing flex={paddingRightFlexCur} flexShrink={0}/> : null}
    </Box>
  );
};

export default Header;