const defaultTheme = {
    transparent: 'rgba(0,0,0,0)',
    white: '#ffffff',
    black: '#000000',
    red: '#ee0000',
    yellow: '#f3c640',
    grey: '#888b8e',
    orange: '#d57200',

    primary: '#414143',

    header: '#414143',
    footer: '#bbbbbd',

    deleteRed: '#D53B3B',

    sendYellow: '#FFD453',
    sendGrey: '#E6E6E6',

    bg1: '#f8f8f8',

    border1: '#e3e3e3',
    border2: '#c1c1c1',
};

const colors = {
    ...defaultTheme,
};

export default colors;