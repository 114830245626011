import React, { useEffect } from "react";
import DefaultLayout from '@layout/DefaultLayout';
import { hs, Assets } from "@hlibs/index";
import { Box, Image, ImageBackground, Spacing } from '@hlibs/react-web/components';
import { useNavigate } from "react-router-dom";

const ViewHome = () =>
{
  return (
    <DefaultLayout>
      <ImageBackground src={Assets.fromImage.png('home/img_main')} width={'100%'} height={588} style={[hs.flexLayout.horizontal()]}>
        <Spacing x={572} flex={1}/>
        <Box style={[hs.flex(1), hs.size.fillParentHeight(), hs.flexLayout.horizontal(), hs.flexAlign.horizontal.leftMiddle()]}>
          <Box style={[hs.size.custom(572, 324), hs.relative()]}>
            <Image src={Assets.fromImage.svg('home/img_yellow_box')} style={[hs.size.fillParent(), hs.mixBlendMode.multiply()]}/>
            <Box style={[hs.absolute.leftTop(41, 36), hs.flexLayout.vertical()]}>
              <Image src={Assets.fromImage.svg('home/logo_white')} width={175} height={44}/>
              <Spacing y={14}/>
              <Image src={Assets.fromImage.svg('home/img_yellow_box_text')} width={300} height={74}/>
            </Box>
          </Box>
        </Box>
      </ImageBackground>
      <Box style={[hs.flex(), hs.size.fillParentWidth(), hs.flexLayout.horizontal(true), hs.flexAlign.horizontal.center()]}>
        <Image src={Assets.fromImage.png('home/sub_menu_01')} width={216} height={179} style={[hs.margin.custom(50, 30, 50, 30)]}/>
        <Image src={Assets.fromImage.png('home/sub_menu_02')} width={216} height={179} style={[hs.margin.custom(50, 30, 50, 30)]}/>
        <Image src={Assets.fromImage.png('home/sub_menu_03')} width={216} height={179} style={[hs.margin.custom(50, 30, 50, 30)]}/>
        <Image src={Assets.fromImage.png('home/sub_menu_04')} width={216} height={179} style={[hs.margin.custom(50, 30, 50, 30)]}/>
      </Box>    
    </DefaultLayout>
  );
};

export default ViewHome;