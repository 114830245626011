import * as hs from '@hlibs/react/hstyles';
import * as Utils from '@hlibs/utils';
import { ReduxData } from '@hlibs/react/redux';

class Modal extends ReduxData
{
    static create = (name) => new Modal(name);

    #_actionIndex = 0;

    updateComponent()
    {
        super.set({ trigger: this.#_actionIndex++ });
    }

    setLoading(show, props = null)
    {
        super.set({ loading: show, loadingProps: props });
    }

    showToast(message, durationSec = 3, props = null)
    {
        super.set({ toastProps: hs.mergeProps(props, { message, startTime: Utils.DateUtil.now(), duration: durationSec * 1000, durationSec, endTime: Utils.DateUtil.addSeconds(durationSec) }) });
    }

    hideToast()
    {
        super.set({ toastProps: null });
    }
};

const reduxModule = Modal.create("Modal");

export default reduxModule;