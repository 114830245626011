import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import Image from './Image';
import Text from './Text';
import Box from './Box';
import * as hs from "@hlibs/react/hstyles"; 
import Spacing from './Spacing';

const Check = forwardRef(({
    defaultValue = false,

    value = null,

    style = null,

    iconWidth = 20,
    iconHeight = 20,

    iconOn = null,
    iconOff = null,

    // 아이콘과 텍스트 사이의 마진
    iconSpacing = 6,

    label = null,
    labelStyle = hs.text.regular(12, '#000000'),  // label 에 string 타입으로 보낼때 사용. 컴포넌트로 보낼땐 사용하지 않음.

    onChange,
}, ref) =>
{
    const [check, setCheck] = useState(defaultValue);

    const checkCur = value != null ? value : check;

    useEffect(() =>
    {
        setCheck(defaultValue);
        
    }, [defaultValue]);

    useImperativeHandle(ref, () => (
    {
        setCheck: (on) =>
        {
            setCheck(on);
        },
        getcheck: () =>
        {
            return value != null ? value : check;
        }
    }));

    if(typeof label == 'string')
    {
        label = (<Text style={labelStyle}>{label}</Text>);
    }

    if(iconOn && (typeof iconOn == 'string' || typeof iconOn == 'number'))
    {
        iconOn = (<Image style={[hs.size.custom(iconWidth, iconHeight)]} src={iconOn}/>);
    }

    if(iconOff && (typeof iconOff == 'string' || typeof iconOff == 'number'))
    {
        iconOff = (<Image style={[hs.size.custom(iconWidth, iconHeight)]} src={iconOff}/>);
    }

    const iconCur = checkCur ? iconOn : iconOff;

    return (
        <Box style={hs.mergeStyles(hs.flexLayout.horizontal(), hs.flexAlign.horizontal.leftMiddle(), style)} activeOpacity={1} onPress={() =>
            {
                let newCheck = !checkCur;

                if(onChange)
                {
                    onChange(newCheck);
                }

                setCheck(newCheck);
            }}>
            { iconCur }
            { iconCur ? <Spacing x={iconSpacing}/> : null }
            { label }
        </Box>    
    );
});

export default Check;