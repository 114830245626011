import * as Sentry from "@sentry/react";
import * as Config from './config';

const USE_SENTRY = Config.SENTRY.run;

if(USE_SENTRY)
{
    const SentryConfig = Config.SENTRY.config;

    if(SentryConfig.dsn)
    {
        Sentry.init(SentryConfig);

        if(Config.MODE !== 'RELEASE')
        {
            console.log("[Sentry] " + SentryConfig.dsn);
        }
    }
}

export const log = (message) =>
{
    if(Array.isArray(message) || typeof message == 'object')
    {
        message = JSON.stringify(message);
    }

    console.log(message);

    if(USE_SENTRY)
    {
        Sentry.captureMessage("LOG : " + message);
    }
};

export const error = (errorMessage) =>
{
    if(Array.isArray(errorMessage) || typeof errorMessage == 'object')
    {
        errorMessage = JSON.stringify(errorMessage);
    }

    console.error(errorMessage);

    if(USE_SENTRY)
    {
        Sentry.captureMessage("ERROR : " + errorMessage);
    }
};

export const exception = (exception) =>
{
    console.error("EXCEPTION : " + exception.stack);

    if(USE_SENTRY)
    {
        Sentry.captureException(exception);
    }
};

export const event = (message, extra) =>
{
    if(Array.isArray(message) || typeof message == 'object')
    {
        message = JSON.stringify(message);
    }

    console.log("EVENT : message = " + message + " | extra = " + extra);
    
    if(USE_SENTRY)
    {
        Sentry.captureEvent({ message, extra });
    }
};
