
import * as Utils from '@hlibs/utils';

// 컴포넌트별 props 분리 위한 클래스
class PropsDivider
{
    static divide(keyArray, props)
    {
        return Utils.popKeysFromObject(keyArray, props);
    }

    static div(props, additionalKeys = [])
    {
        const commonProps = ['children', 'onClick', 'onMouseDown', 'onMouseUp', 'onMouseLeave'];

        return this.divide([...additionalKeys, ...commonProps], props);
    }

    static image(props, additionalKeys = [])
    {
        // https://www.w3schools.com/tags/tag_img.asp

        const commonProps = ['src', 'width', 'height', 'alt', 'sizes', 'crossorigin', 'ismap', 'loading', 'longdesc', 'referrerpolicy', 'srcset', 'usemap'];

        return this.divide([...additionalKeys, ...commonProps], props);
    }

    static input(props, additionalKeys = [])
    {
        // https://www.w3schools.com/tags/tag_input.asp

        const commonProps = ['value', 'width', 'placeholder', 'accept', 'alt', 'autocomplete', 'autofocus', 'checked', 'dirname', 'disabled', 'form', 'formaction', 'formenctype', 'formmethod', 'formnovalidate', 'formtarget', 'height', 'list', 'max', 
            'maxlength', 'min', 'minlength', 'multiple', 'name', 'pattern', 'readonly', 'required', 'size', 'src', 'step', 'type', 'onKeyPress'];

        return this.divide([...additionalKeys, ...commonProps], props);
    }
}

export default PropsDivider;