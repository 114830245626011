import moment from 'moment';

export default class DateUtil
{
    static date2str = (date, format = "yyyy-MM-dd") =>
    {
        if(!date) return null;

        var z = {
            M: date.getMonth() + 1,
            d: date.getDate(),
            h: date.getHours(),
            m: date.getMinutes(),
            s: date.getSeconds()
        };
        format = format.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
            return ((v.length > 1 ? "0" : "") + eval('z.' + v.slice(-1))).slice(-2)
        });

        return format.replace(/(y+)/g, function(v) {
            return date.getFullYear().toString().slice(-v.length)
        });
    };

    static now = (format = null) =>
    {
        let dateResult = moment().toDate();

        return format ? this.date2str(dateResult, format) : dateResult;
    };

    static addSeconds = (seconds, date = null, format = null) =>
    {
      let dateFrom = date ? new Date(date) : moment().toDate();
    
      let dateResult = new Date(dateFrom);
    
      dateResult.setSeconds(dateFrom.getSeconds() + seconds);
    
      return format ? this.date2str(dateResult, format) : dateResult;
    };

    static addMinutes = (minutes, date = null, format = null) =>
    {
      let dateFrom = date ? new Date(date) : moment().toDate();
    
      let dateResult = new Date(dateFrom);
    
      dateResult.setMinutes(dateFrom.getMinutes() + minutes);
    
      return format ? this.date2str(dateResult, format) : dateResult;
    };

    static addHours = (hours, date = null, format = null) =>
    {
      let dateFrom = date ? new Date(date) : moment().toDate();
    
      let dateResult = new Date(dateFrom);
    
      dateResult.setHours(dateFrom.getHours() + hours);
    
      return format ? this.date2str(dateResult, format) : dateResult;
    };

    static addDays = (days, date = null, format = null) =>
    {
      let dateFrom = date ? new Date(date) : moment().toDate();
    
      let dateResult = new Date(dateFrom);
    
      dateResult.setDate(dateFrom.getDate() + days);
    
      return format ? this.date2str(dateResult, format) : dateResult;
    };
    
    static addMonths = (months, date = null, format = null) =>
    {
      let dateFrom = date ? new Date(date) : moment().toDate();
    
      let dateResult = new Date(dateFrom);
    
      dateResult.setMonth(dateFrom.getMonth() + months);
    
      return format ? this.date2str(dateResult, format) : dateResult;
    };
    
    static addYears = (years, date = null, format = null) =>
    {
      let dateFrom = date ? new Date(date) : moment().toDate();
    
      let dateResult = new Date(dateFrom);
    
      dateResult.setFullYear(dateFrom.getFullYear() + years);
    
      return format ? this.date2str(dateResult, format) : dateResult;
    };

    static getFirstDayInMonth = (date = null, format = null) =>
    {
        let dateFrom = date ? new Date(date) : moment().toDate();

        let dateResult = moment(this.date2str(dateFrom, 'yyyy-MM') + '-01').toDate();

        return format ? this.date2str(dateResult, format) : dateResult;
    };

    static getLastDayInMonth = (date = null, format = null) =>
    {
        let dateFrom = date ? new Date(date) : moment().toDate();

        dateFrom.setMonth(dateFrom.getMonth() + 1);

        let dateResult = moment(this.date2str(dateFrom, 'yyyy-MM') + '-01').toDate();

        dateResult.setDate(dateResult.getDate() - 1);

        return format ? this.date2str(dateResult, format) : dateResult;
    };

    static getWeekDayStr = (date) => 
    {
        const weekday = ['일', '월', '화', '수', '목', '금', '토'];

        return weekday[date.getDay()];
    };      

    // startFromMonday == true ? 월요일부터 한주 : 일요일부터 한주
    static getWeekMondayDate = (startFromMonday = false, date = null, format = null) =>
    {
        const weekdayAdd = startFromMonday ? [-6, 0, -1, -2, -3, -4, -5] : [1, 0, -1, -2, -3, -4, -5];

        let dateFrom = date ? new Date(date) : moment().toDate();

        let dateResult = new Date(dateFrom);

        dateResult.setDate(dateFrom.getDate() + weekdayAdd[date.getDay()]);
        
        return format ? this.date2str(dateResult, format) : dateResult;
    };
};