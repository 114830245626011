import * as Crypto from './crypto';
import * as Config from './config';
import moment from 'moment';

export default class ExpireData
{
    static DefaultSecret = Config.CRYPTO.AES_SECRET;

    static makeData(data, expireSec = null)
    {
        return this.encrypt(data, expireSec, null);
    }

    static encrypt(data, expireSec = null, aesSecret = ExpireData.DefaultSecret)
    {
        let expiredTime = null;

        if(expireSec)
        {
            let dateExpired = new Date();

            dateExpired.setSeconds(moment().toDate().getSeconds() + expireSec);

            expiredTime = dateExpired;
        }
        
        let dataEnc = {
            data: aesSecret ? Crypto.SHAAES.encrypt(JSON.stringify(typeof data == 'object' ? data : { data }), aesSecret) : data,
            type: typeof data,
            encrypted: aesSecret ? true : false,
            expiredTime
        };

        return aesSecret ? JSON.stringify(dataEnc) : dataEnc;
    }

    static readData(encodedData, ignoreExpireTime = false)
    {
        return this.decrypt(encodedData, null, ignoreExpireTime);
    }

    static decrypt(encodedData, aesSecret = ExpireData.DefaultSecret, ignoreExpireTime = false)
    {
        let dataDec = null;
        
        if(encodedData == null) return null;

        try
        {
            let dataObj = typeof encodedData == 'string' ? JSON.parse(encodedData) : encodedData;

            try
            {
                if(!ignoreExpireTime && dataObj.expiredTime)
                {
                    let dateExpired = moment(dataObj.expiredTime).toDate();

                    let dateNow = moment().toDate();    

                    if(dateExpired < dateNow)
                    {
                        return null;
                    }
                }

                dataDec = aesSecret && dataObj.data ? Crypto.SHAAES.decrypt(dataObj.data, aesSecret) : dataObj.data;

                if(typeof dataDec == 'string' && dataObj.encrypted == true)
                {
                    dataDec = JSON.parse(dataDec);

                    if(dataObj.type != 'object')
                    {
                        dataDec = dataDec.data;
                    }
                }
            }
            catch(e) 
            { 
                dataDec = dataObj.data;
            }
        }
        catch(e)
        {
            dataDec = null;
        }

        return dataDec;
    }
};