import React from 'react';
import Spacing from './Spacing';
import Box from './Box';
import ButtonRound from './ButtonRound';
import * as hs from '@hlibs/react/hstyles';
import colors from '@hlibs/react/colors';
import * as Utils from '@hlibs/utils';

const strings = {
    ok: 'ok',
    cancel: 'cancel',
    yes: 'yes',
    no: 'no'
};

export const makeButton = (label, bgColor = '#ffffff', textColor = colors.black, style = null, labelStyle = null) =>
{
    return { label, textColor, bgColor, style, labelStyle };
};

export class ButtonGroupExt
{
    static makeButton = makeButton;
    
    static presetButtons = {
        ok: [makeButton(strings.ok)],
        okCancel: [makeButton(strings.cancel, colors.grey), makeButton(strings.ok)],
        yes: [makeButton(strings.yes)],
        yesNo: [makeButton(strings.no, colors.grey), makeButton(strings.yes)],
    };    
};

const defaultProps = 
{
    buttons: ButtonGroupExt.presetButtons.ok,

    onCallback: (buttonIndex, label) => {},

    spacingX: 15,
    spacingY: 10,

    lineMaxButtonsCount: 2,
};

const defaultStyles = hs.mergeStyles(

);

const defaultButtonStyles = hs.mergeStyles(
    hs.size.fillParentWidth(), 
    hs.radius.all(25, colors.yellow), 
    hs.verticalLayout(), 
    hs.verticalAlign.center()  
);

const defaultButtonLabelStyles = hs.mergeStyles(
    hs.text.medium(20, colors.black)
);

export function ButtonGroup(props = defaultProps)
{
    const mergedProps = hs.mergeProps(defaultProps, props);

    const style = hs.mergeStyles(defaultStyles, mergedProps.style);

    const { buttons, onCallback, spacingX, spacingY, lineMaxButtonsCount } = mergedProps;

    const buttonGroup = Utils.makeArrayGroup(buttons, lineMaxButtonsCount);

    return (
        <Box style={[hs.size.fillParentWidth(), hs.verticalLayout(), hs.verticalAlign.center(), style]}>
        {
            buttonGroup.map((row, indexRow) => 
            (
                <Box key={indexRow} style={[hs.margin.top(indexRow > 0 ? spacingY : 0), hs.size.fillParentWidth(), hs.horizontalLayout(), hs.horizontalAlign.center()]}>
                    {
                        row.map((item, indexCol) =>
                        (
                            <React.Fragment key={indexCol}>
                                {indexCol > 0 ? <Spacing x={spacingX}/> : null}
                                <ButtonRound label={item?.value?.label} labelStyle={[defaultButtonLabelStyles, hs.text.color(item?.value?.textColor), item?.value?.labelStyle]} style={[hs.flex(), defaultButtonStyles, hs.backgroundColor(item?.value?.bgColor || colors.yellow), item?.value?.style]} 
                                    onPress={() => onCallback(item?.index, item?.value?.label)} />
                            </React.Fragment>
                        ))
                    }
                </Box>
            ))
        }
        </Box>
    )
};

export default ButtonGroup;