import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import PropsDivider from '@hlibs/react/props-divider';
import * as hs from "@hlibs/react/hstyles"; 
import Box from './Box';
import Spacing from './Spacing';
import colors from '@hlibs/react/colors';

// https://reactnative.dev/docs/textinput

const defaultProps = 
{
    inputStyle: null,

    iconLeft: null,    
    iconRight: null,    

    iconSpacing: 5
};

const defaultStyles = hs.mergeStyles(
    hs.flexLayout.horizontal(),
    hs.flexAlign.horizontal.middle(),
    hs.backgroundColor(colors.white)
);

const defaultInputStyles = hs.mergeStyles(
    hs.flex(),
    hs.text.regular(12, colors.black),
    hs.border.all('none')
);

const InputBox = forwardRef((props = defaultProps, ref) =>
{
    const mergedProps = hs.mergeProps(defaultProps, props);

    const style = hs.mergeStyles(defaultStyles, mergedProps.style);

    // input props 분리
    const inputProps = PropsDivider.input(mergedProps, ['inputStyle', 'inputTextReplacer']);

    const inputStyle = hs.mergeStyles(defaultInputStyles, inputProps.inputStyle);

    const inputTextReplacer = inputProps.inputTextReplacer;

    delete inputProps.inputStyle;
    delete inputProps.inputTextReplacer;

    const inputRef = useRef();

    const [value, setValue] = useState(inputProps.defaultValue);

    const getValue = () => { return inputProps.value ? inputProps.value : value; }

    if(inputProps.value != null && inputProps.value != getValue())
    {
        inputRef.current.value = getValue();
    }

    useImperativeHandle(ref, () => ({
        setValue: setValue,
        getValue: getValue,
    }));    

    if(inputProps.nextInputBox) inputProps.blurOnSubmit = false;

    const onChangeText = (event) =>
    {
        let val = event.target.value;

        if(inputTextReplacer)
        {
            val = inputTextReplacer(event.target.value);
        }

        setValue(val);

        if(inputProps.onChangeText) inputProps.onChangeText(val);
    };

    const onKeyPress = (event) =>
    {
        if(inputProps.onKeyPress) inputProps.onKeyPress(event);

        if(mergedProps.onSubmit && event.key == "Enter") mergedProps.onSubmit(value);
    };

    const inputComponent = <input {...inputProps} ref={inputRef} onChange={onChangeText} onKeyPress={onKeyPress} style={inputStyle}/>;

    return (
        <Box {...mergedProps} style={style}>
            {mergedProps.iconLeft}
            {mergedProps.iconLeft ? <Spacing x={mergedProps.iconSpacing}/> : null}
            {inputComponent}
            {mergedProps.iconRight ? <Spacing x={mergedProps.iconSpacing}/> : null}
            {mergedProps.iconRight}
        </Box>
    )
});

export default InputBox;