const defaultTheme = {
    transparent: 'rgba(0,0,0,0)',
    white: '#ffffff',
    black: '#000000',
    red: '#ee0000',
    yellow: '#f7c542',
    blue: '#4D53F5',
    grey: '#888b8e',

    primary: '#000000',
};

const colors = {
    ...defaultTheme,
};

export default colors;