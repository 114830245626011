import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ViewHome from '@views/ViewHome';
import ViewMain from '@views/ViewMain';
import ViewLogin from '@views/ViewLogin';
import ViewAdmin from '@views/ViewAdmin';
import { ModalComponents } from '@hlibs/index'

function App() 
{
  return (
    <>
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<ViewHome/>} />
              <Route path="/login" element={<ViewLogin/>} />
              <Route path="/admin" element={<ViewAdmin/>} />
              <Route path="/main" element={<ViewMain/>} />
          </Routes>
      </BrowserRouter>  
      <ModalComponents backgroundColor={'#00000090'}/>
    </>
  );
}

export default App;
