import * as Debug from "./debug";

class KeyGenerator
{
    static #_prefix = 'KG-';

    static #_groupIndexPos = 0;

    static create()
    {
        return new KeyGenerator();
    }
    
    #_index = 0;
    #_groupIndex = 0;
    
    constructor()
    {
        this.#_groupIndex = ++KeyGenerator.#_groupIndexPos;
    }

    generate(key = null)
    {
        return KeyGenerator.#_prefix + this.#_groupIndex + '-' + (key || ++this.#_index);
    }

    getKey(key)
    {
        if(!key) { Debug.error('getKey() 에서 key 는 null 이 될 수 없습니다.'); return null; }

        return KeyGenerator.#_prefix + this.#_groupIndex + '-' + key;
    }
}

export default KeyGenerator;