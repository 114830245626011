import { ReduxSagaSocket } from '@hlibs/react/redux';
import AppConfig from '@app/config';
import * as Crypto from '@hlibs/crypto';
import { Utils } from '@hlibs/index';
import Handlers from './handler';

class Socket extends ReduxSagaSocket
{
    static create = (name, address, token, config = { reconnect: true, transports: ['websocket'] }, funcAsync = null, funcResult = null) => new Socket(name, address, token, config, funcAsync, funcResult);
};

const maxHistoryCount = 100;

const kycHistory = [];

const onConnect = (socket) =>
{
    socket.on('kyc_response', (recvData) =>
    {
        try
        {
            if(recvData)
            {
                const { data } = JSON.parse(recvData);

                if(data?.kyc_id && Utils.findInArray(data?.kyc_id, kycHistory) == false)
                {
                    kycHistory.push(data?.kyc_id);

                    if(kycHistory.length > maxHistoryCount)
                    {
                        kycHistory.splice(0, maxHistoryCount / 10);
                    }

                    Handlers.OnKycResponse.invoke(data);
                }
            }    
        }
        catch(e)
        {
//                Debug.error(e);
        }
    });
};

const socketAuthKey = 'iRLpOrsVGVZCcOiwIfk@/biK-]NjYio_';

const reduxModule = Socket.create("Socket", AppConfig.SOCKET.url, Crypto.AES.encrypt(JSON.stringify({ id: 'tester', name: '관리자' }), socketAuthKey), { reconnect: true, transports: ['websocket'] }, onConnect);

export default reduxModule;
