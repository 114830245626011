import React, { useEffect, useState } from 'react';
import { Box, Text } from '@hlibs/react-web/components';
import * as hs from "@hlibs/react/hstyles"; 
import * as Utils from '@hlibs/utils';
import { Animated } from "react-animated-css";

// https://www.npmjs.com/package/react-animated-js

export const defaultProps = {
    message: "",
    textSize: 20,
    lineHeight: "140%",
    maxWidth: "60%",
    letterSpacing: 1,


    textColor: '#ffffff',
    bgColor: '#444444' + Utils.opacity2hex(0.85),

    fadeInOutDuration: 250,

    textStyle: null,
    bgStyle: null
};

const Toast = (props) =>
{
  const [timer, setTimer] = useState(null);
  
  const mergedProps = hs.mergeProps(defaultProps, props);

  const styles = mergedProps.style ? hs.mergeStyles(mergedProps.style) : {};
    
  const { duration, startTime, hide, message, textSize, lineHeight, textColor, bgColor, textStyle, bgStyle } = mergedProps;

  const [visible, setVisible] = useState(false);

  useEffect(() =>
  {
    if(timer)
    {
        clearTimeout(timer);

        setTimer(null);
    }

    setVisible(true);

    const timerId = setTimeout(() => 
    {
        setVisible(false);

        const hideTimerId = setTimeout(() => 
        {
            hide();
        }, mergedProps.fadeInOutDuration);

        setTimer(hideTimerId);
    }, duration);

    setTimer(timerId);

  }, [startTime]);

  return (
    <Box style={[hs.fixed.leftTop(), hs.size.fillParent(), hs.verticalLayout(), hs.verticalAlign.center(), hs.reactWeb.pointerEvents.none(), styles]} pointerEvents={'box-none'}>  
        <Box style={[hs.flex(2), hs.size.fillParentWidth()]} />
        <Box style={[hs.flex(1), hs.size.fillParentWidth(), hs.verticalLayout(), hs.verticalAlign.center()]}>
            <Animated animationIn="fadeIn" animationInDuration={mergedProps.fadeInOutDuration} animationOut="fadeOut" animationOutDuration={mergedProps.fadeInOutDuration} isVisible={visible} style={hs.mergeStyles(hs.size.maxWidth(mergedProps.maxWidth))} >
                <Box style={[hs.padding.custom(20, 10, 20, 10), hs.radius.all(12, bgColor), hs.verticalLayout(true), hs.verticalAlign.center(), bgStyle]}>
                    <Text style={[hs.color(textColor), hs.text.wordBreak.breakAll(), hs.text.letterSpacing(mergedProps.letterSpacing), hs.text.size(textSize), hs.lineHeight(lineHeight), textStyle]}>{message}</Text>
                </Box>
            </Animated>
        </Box>
    </Box>
  );
};

export default Toast;