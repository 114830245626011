import * as hs from "@hlibs/react/hstyles"; 

const defaultProps = {
    src: null,
    width: null,
    height: null,
    alt: ""
};

const defaultStyles = {
  
};

const Component = (props = defaultProps) =>
{
    const mergedProps = hs.mergeProps(defaultProps, props);

    const styles = hs.mergeStyles(defaultStyles, mergedProps.style);

    if(mergedProps.source && !mergedProps.src)
    {
        mergedProps.src = mergedProps.source;

        delete mergedProps.source;
    }

    return (
        <img {...mergedProps} style={styles}/>
    )
};

export default Component;