import React from 'react';
import Box from './Box';
import Text from './Text';
import PropsDivider from '@hlibs/react/props-divider';
import * as hs from '@src/lib/hlibs/react/hstyles';
import colors from '@hlibs/react/colors';

const defaultProps = 
{
    label: null,
    labelStyle: null,

    onPress: null
};

const defaultStyles = hs.mergeStyles(
    hs.size.fillParentWidth(), 
    hs.radius.all(25, colors.yellow), 
    hs.padding.all(12),
    hs.verticalLayout(), 
    hs.verticalAlign.center()  
);

const defaultLabelStyles = hs.mergeStyles(
    hs.text.medium(28, colors.black)
);

const ButtonRound = (props = defaultProps) =>
{
    const mergedProps = hs.mergeProps(defaultProps, props);

    const style = hs.mergeStyles(defaultStyles, mergedProps.style);

    // Text(label) props 분리
    const labelProps = PropsDivider.div(mergedProps, ['label', 'labelStyle', 'children']);

    const labelStyle = hs.mergeStyles(defaultLabelStyles, labelProps.labelStyle);

    delete labelProps.labelStyle;

    return (
        <Box {...mergedProps} style={[style, !mergedProps.disabled ? null : mergedProps.disabledColor ? hs.backgroundColor(mergedProps.disabledColor) : hs.opacity(0.5)]}>
            {typeof labelProps.label == 'object' ? labelProps.label : <Text {...labelProps} style={labelStyle}>{labelProps.label}{labelProps.children}</Text>}
        </Box>
    );
};

export default ButtonRound;