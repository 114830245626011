import React from "react";
import * as hs from "@hlibs/react/hstyles"; 
import { colors } from '@app/index';
import { Box } from '@hlibs/react-web/components';

const defaultProps = 
{
  maxWidth: 1600,
  maxHeight: null,

  minWidth: 600,
  minHeight: null,

};

const defaultStyles = hs.mergeStyles(
  hs.flexLayout.vertical(), 
  hs.flexAlign.vertical.centerTop()  
);

const BaseLayout = (props) =>
{
  const mergedProps = hs.mergeProps(defaultProps, props);

  const styles = hs.mergeStyles(defaultStyles, mergedProps.style);

  const { children, maxWidth, maxHeight, minWidth, minHeight } = mergedProps;

  return (
    <Box style={[hs.backgroundColor(colors.white), hs.size.width('100vw'), hs.size.height('100vh'), hs.flexLayout.vertical(), hs.flexAlign.vertical.centerTop()]}>
      <Box style={[maxWidth ? hs.size.maxWidth(maxWidth) : null, maxHeight ? hs.size.maxHeight(maxHeight) : null, minWidth ? hs.size.minWidth(minWidth) : null, minHeight ? hs.size.minHeight(minHeight) : null, hs.size.fillParent(), styles]}>
        {children}
      </Box>
    </Box>
  );
};

export default BaseLayout;