import * as Debug from '@hlibs/debug';
//import { useSelector, shallowEqual } from 'react-redux';

// store/name 에 데이터 저장
// ex) const reduxData = ReduxData.create(name);
export default class ReduxData
{    
    static create = (name) => new ReduxData(name);

    // set state
    set(data, key = null)
    {
        if(!this.#_store)
        {
            Debug.error(this.constructor.name + '.setStore 로 store 를 지정해주세요.');

            return;
        }

        this.#_store.dispatch(this.#_actions.Set(data, key));
    };

    // set state multiple => object type 으로 전달
    setMultiple(dataObject)
    {
        if(!this.#_store)
        {
            Debug.error(this.constructor.name + '.setStore 로 store 를 지정해주세요.');

            return;
        }

        this.#_store.dispatch(this.#_actions.SetMultiple(dataObject));
    };

    // states 초기화 : preserveExtra => 초기화시 extra 보존 여부
    reset(preserveExtra = false)
    {
        if(!this.#_store)
        {
            Debug.error(this.constructor.name + '.setStore 로 store 를 지정해주세요.');

            return;
        }

        this.#_store.dispatch(this.#_actions.Reset(preserveExtra));
    }

    // extra 에 key / value 데이터 저장
    setExtra(key, data)
    {   
        if(!this.#_store)
        {
            Debug.error(this.constructor.name + '.setStore 로 store 를 지정해주세요.');

            return;
        }
        
        if(key == null)
        {
            Debug.error(this.constructor.name + '.setExtra => key 값을 입력해주세요');

            return;
        }

        const state = this.getState();

        const extra = state.extra ? state.extra : {};

        if(data != null)
        {
            extra[key] = data;
        }

        else
        {
            delete extra[key];
        }

        this.#_store.dispatch(this.#_actions.SetExtra(extra));
    }

/*
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // rule 에 의해 useSelector 를 포함한 hook 함수들은 각 리액트 컴포넌트에서만 사용할 것
    useSelector = () =>
    {
        return useSelector((state) => state[this.#_name], shallowEqual);
    };
*/    

    constructor(name)
    {
        if (typeof name !== 'string') 
        {
            Debug.error('name must be a string');
        }
    
        const nameUpperCase = name.toUpperCase();

        const initialState = {};

        const actionTypes = {
            Set: nameUpperCase + '/SET',
            SetMultiple: nameUpperCase + '/SET_MULTIPLE',
            Reset: nameUpperCase + '/RESET',
            SetExtra: nameUpperCase + '/SET_EXTRA',
        };    

        const actions = {
            Set: (data, key) => { return { type: actionTypes.Set, data, key }; },
            SetMultiple: (dataObject) => { return { type: actionTypes.SetMultiple, dataObject }; },
            Reset: (preserveExtra = false) => { return { type: actionTypes.Reset, preserveExtra }; },
            SetExtra: (extra) => { return { type: actionTypes.SetExtra, extra }; },
        };

        const reducer = (state = initialState, action) => 
        {
            switch (action.type) 
            {
                case actionTypes.Set:
                    return action.key ? {
                        ...state,
                        [action.key] : action.data
                    } : {
                        ...state,
                        ...action.data
                    };    
                case actionTypes.SetMultiple:
                    return {
                        ...state,
                        ...action.dataObject
                    };
                case actionTypes.Reset:
                    return {
                        ...initialState,
                        extra : action.preserveExtra ? state.extra : null
                    };    
                case actionTypes.SetExtra:
                    return {
                        ...state,
                        extra: action.extra
                    };
                default:
                    return state;
            };
        };

        this.#_name = name;
        this.#_actionTypes = actionTypes;
        this.#_actions = actions;
        this.#_reducer = reducer;

        return this;
    }

    getState(key = null) 
    { 
        const state = this.#_store ? this.#_store.getState() : null;

        return !state ? null : key ? state[this.#_name][key] : state[this.#_name];
    };

    setStore(store) { this.#_store = store; };

    #_store = null;
    #_name = "";

    #_actions = null;
    #_reducer = null;    
    #_actionTypes = null;

    get name() { return this.#_name; };
    get actions() { return this.#_actions; };
    get actionTypes() { return this.#_actionTypes; };
    get reducer() { return this.#_reducer; };
    get saga() { return null; };
    get store() { return this.#_store; };


};