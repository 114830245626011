import React, { useEffect } from "react";
import * as hs from "@hlibs/react/hstyles"; 
import { HeaderFooterLayout } from "@hlibs/react-web/layouts";
import reduxConfig from '@redux/config';

import Header from "./Header";
import Footer from './Footer';

const defaultProps = 
{
  maxWidth: 1920,
  maxHeight: 1080,

  minWidth: 600,

};

const defaultStyles = {
  
};

const DefaultLayout = (props) =>
{
  const mergedProps = hs.mergeProps(defaultProps, props);

  const styles = hs.mergeStyles(defaultStyles, mergedProps.style);

  const { children } = props;

  useEffect(() =>
  {
    reduxConfig.getSettings();
  }, []);



  const headerComponent = <Header/>;
  const footerComponent = <Footer/>;

  return (
    <HeaderFooterLayout header={headerComponent} footer={footerComponent} maxWidth={mergedProps.maxWidth} maxHeight={mergedProps.maxHeight} style={[styles]}>
      {children}
    </HeaderFooterLayout>
  );
};

export default DefaultLayout;