const en = {
    Server: {
        err1: 'Server Error\nPlease try again later.',
        err2: "Parameter Error",
        err3: "Please enter your ID and password",
    },
    Socket: {
        msg1: (id) => `Verification Complete!\n(${id})`,
        msg2: (id) => `Verification Failed!\n(${id})`
    },
    KYC: {
        msg1: "Notification is successfully sent.",
        msg2: "Failed to send push notification."
    },
    USER: {
        msg1: "Deleted successfully!",
        msg2: "Failed to delete the user data."
    },
    OTAC: {
        err1: "Please enter an OTAC.",
        err2: "This code has already been used.(Duplicate)",
        err3: "Authentication failed.",
        msg1: "Authentication Success.",
    },
    ViewAdmin: {
        id: "ID",
        name: "Name",
        phone: "Phone Number",
        email: "E-mail",
        msg1: "Are you sure you want to delete the user data?",
        label1: "Search",
        Setting: {
            label1: "Sign-in Option",
            label2: "Allow Login with 1st OTAC"
        }
    },
    QRDialog: {
        msg1: "Please log in with 1st OTAC.",
        msg2: "Please log in with 2nd OTAC."
    }

};

export default en;