import { useState, useEffect } from "react";
import Handlers from './handler';

export const useKycResponse = () =>
{
    const [response, setResponse] = useState(null);

    useEffect(() =>
    {
        const kycResponseListenerId = Handlers.OnKycResponse.add((data) =>
        {
            setResponse(data);
        });

        return () =>
        {
            Handlers.OnKycResponse.remove(kycResponseListenerId);
        };
    }, []);

    return response;
};