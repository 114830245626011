import * as hs from "@hlibs/react/hstyles"; 
import Box from './Box';

const defaultProps = {
    color: null,
    size: null
};

const TextBox = (props = defaultProps) =>
{
    const mergedProps = hs.mergeProps(defaultProps, props);

    const styles = mergedProps.style ? hs.mergeStyles(mergedProps.style) : {};

    if(mergedProps.color) styles.color = mergedProps.color;
    if(mergedProps.size) styles.fontSize = mergedProps.size;

    return (
        <Box {...mergedProps} style={styles}/>
    )
};

export default TextBox;