import * as Config from '@hlibs/config';
import ExpireData from '@hlibs/expire-data';

export default class LocalStorage
{
    static #_AES_SECRET = Config.CRYPTO.COOKIE_SECRET;

    static setSecret(secret)
    {
        this.#_AES_SECRET = secret;
    }

    static async load(name)
    {
        return this.getItem(name);
    }

    static async get(name)
    {
        return this.getItem(name);
    }

    static async getItem(name)
    {
        let val = localStorage.getItem(name);

        return ExpireData.decrypt(val, this.#_AES_SECRET);
    }

    static async save(name, data, expireSec = null)
    {
        return this.setItem(name, data, expireSec);
    }

    static async set(name, data, expireSec = null)
    {
        return this.setItem(name, data, expireSec);
    }

    static async setItem(name, data, expireSec = null)
    {
        if(data == null)
        {
            this.delete(name);
        }

        else
        {
            const dataEnc = ExpireData.encrypt(data, expireSec, this.#_AES_SECRET);

            localStorage.setItem(name, dataEnc);
        }
    }

    static async delete(name)
    {
        localStorage.removeItem(name);
    }
}