import { Debug, Utils, ReduxSagaRequest, Request } from '@hlibs/index';
import Language from '@app/language';

export const SignUp = async (payload, reduxModule) =>
{
    const stateKey = 'SignUp';

    if(Utils.checkNullOrEmpty(payload)) return ReduxSagaRequest.makeResult.failureWithMessage(Language.Server.err2, stateKey);

    try
    {
        const { data } = await Request.post('preset[1]/account/signup', payload);

        if(data?.error)
        {
            return ReduxSagaRequest.makeResult.failure(data, stateKey);
        }

        else if(Utils.checkKeyExists(data, ['id']) || Utils.checkKeyExists(data, ['result']))
        {
            return ReduxSagaRequest.makeResult.success(data, stateKey);
        }    
    }
    catch(e)
    {
        if(process.env.MODE !== 'RELEASE') Debug.exception(e);
    }

    return ReduxSagaRequest.makeResult.failureWithMessage(Language.Server.err1, stateKey);
};

export const Login = async (payload, reduxModule) =>
{
    const stateKey = 'Login';

    if(Utils.checkNullOrEmpty(payload, payload.id, payload.pw)) return ReduxSagaRequest.makeResult.failureWithMessage(Language.Server.err3, stateKey);

    try
    {
        const { data } = await Request.post('preset[1]/account/login', payload);

        if(data?.error)
        {
            return ReduxSagaRequest.makeResult.failure(data, stateKey);
        }

        else if(Utils.checkKeyExists(data, ['id']))
        {
            reduxModule.set(data, 'user'); 

            return ReduxSagaRequest.makeResult.success(data, stateKey);
        }    
    }
    catch(e)
    {
        if(process.env.MODE !== 'RELEASE') Debug.exception(e);
    }

    return ReduxSagaRequest.makeResult.failureWithMessage(Language.Server.err1, stateKey);
}; 

export const TokenCheck = async (reduxModule) =>
{
    const stateKey = 'TokenCheck';

    try
    {
        const { data } = await Request.post('preset[1]/account/check');

        if(data?.error?.status_code == 401)
        {
            reduxModule.set(true, 'checked'); 

            return ReduxSagaRequest.makeResult.success(null, stateKey);
        }

        else if(Utils.checkKeyExists(data, ['id']))
        {
            reduxModule.setMultiple({
                checked: true,
                user: data
            });
        
            return ReduxSagaRequest.makeResult.success(data, stateKey);
        }    

        reduxModule.set(true, 'checked'); 
    }
    catch(e)
    {
        if(process.env.MODE !== 'RELEASE') Debug.exception(e);
    }

    return ReduxSagaRequest.makeResult.failureWithMessage(Language.Server.err1, stateKey);
};

export const Logout = async (reduxModule) =>
{
    const stateKey = 'Logout';

    try
    {
        const { data } = await Request.post('preset[1]/account/logout');

        if(data?.error?.status_code == 401 || Utils.checkKeyExists(data, ['token']) || (data && Object.keys(data).length < 1))
        {
            reduxModule.set(null, 'user'); 

            return ReduxSagaRequest.makeResult.success(data, stateKey);
        }    
    }
    catch(e)
    {
        if(process.env.MODE !== 'RELEASE') Debug.exception(e);
    }

    return ReduxSagaRequest.makeResult.failureWithMessage(Language.Server.err1, stateKey);
};

