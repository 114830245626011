import React, { useEffect, useState } from 'react';
import { hs, Modal } from '@hlibs/index';
import { Box, Text, ButtonGroup, ButtonGroupExt } from '@hlibs/react-web/components';
import QrReader from 'react-qr-reader'
import reduxVerification from '@redux/verification'
import { colors } from '@app/index';
import { useSelector } from 'react-redux';
import Language from '@app/language';

const defaultProps = 
{
    title: null,
    message: null,

    buttons: [ButtonGroupExt.makeButton('close', '#eaeaea')],

    callback: (index) => {},

    spacingX: 15,
    spacingY: 10,

    lineMaxButtonsCount: 2,
};

const defaultStyles = hs.mergeStyles(
    hs.size.width(600), 
    hs.verticalLayout(), 
    hs.verticalAlign.center()  
);

let lastVerifyCode = "";

function QRCodeDialog(props = defaultProps)
{
    const [inlineMessage, setInlineMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [success, setSuccess] = useState(false);

    const mergedProps = hs.mergeProps(defaultProps, props);

    const verification = useSelector((state) => state.Verification);

    const style = hs.mergeStyles(defaultStyles, mergedProps.style);

    const { close, callback, allow1stOtac } = mergedProps;

    const onCallback = () =>
    {
        if(callback)
        {
            callback({ result: false });
        }

        close();
    }

    useEffect(() => { lastVerifyCode = ""; reduxVerification.reset(); }, []);

    useEffect(() =>
    {
        if(verification?.error?.message)
        {
            setErrorMessage(verification?.error?.message);
        }

        else if(verification?.response?.messsage)
        {
            setErrorMessage(null);
            setInlineMessage(verification?.response?.message);
        }

        if(verification?.response?.result)
        {
            setSuccess(true);

            if(callback)
            {
                callback({ result: true });
            }

            close();
        }
    }, [verification]);

    const handleScan = (data) => 
    {
        if(success == true) return;
        
        if(typeof data == 'string' && data?.length > 0)
        {
          const arrData = data.split(':');
	  
	  if(lastVerifyCode != arrData[1])
          {
            if(arrData[0] == 'otac1' && !allow1stOtac)
            {
                setErrorMessage(Language.QRDialog.msg2);

                return;
            }

            else if(arrData[0] == 'otac2' && allow1stOtac)
            {
                setErrorMessage(Language.QRDialog.msg1);

                return;
            }

            lastVerifyCode = arrData[1];

            if(allow1stOtac) reduxVerification.verify1st(arrData[1], arrData[2]);
            else reduxVerification.verify2nd(arrData[1], arrData[2], arrData[3]);            
          }

        }
    }

    const handleError = (error) => 
    {
        setErrorMessage(error.message);
    }

    return (
        <Box style={[hs.padding.leftRightAll(40), hs.verticalLayout(), hs.verticalAlign.center(), style]}>
            <Box style={[hs.size.fillParentWidth(), hs.verticalLayout(), hs.verticalAlign.center(), hs.radius.all(12), hs.backgroundColor(colors.white), hs.shadow(), hs.padding.topBottomAll(6), hs.padding.leftRightAll(20)]}>
                <Box style={[hs.size.fillParentWidth(), hs.verticalLayout(), hs.verticalAlign.center(), hs.padding.custom(25, 25, 25, 15)]}>
                <QrReader
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    facingMode={'user'}
                    style={{ width: '100%' }}
                    />
                </Box>
                { errorMessage ? <Text style={[hs.text.regular(24, colors.black), hs.text.align.center()]}>{errorMessage}</Text> : inlineMessage ? <Text style={[hs.text.regular(24, colors.black), hs.text.align.center()]}>{inlineMessage}</Text> : null}
                <ButtonGroup {...mergedProps} onCallback={onCallback} style={[hs.padding.custom(25, 15, 25, 15)]}/>
            </Box>
        </Box>
    )
};

export default class ModalController
{
    #_component = null;

    static create()
    {
        return new this();
    }

    modal(callback, allow1stOtac)
    {
        const mergedProps = { callback, allow1stOtac };

        this.#_component = <QRCodeDialog {...mergedProps} close={() => this.close()} />;

        Modal.push(this.#_component);
    }

    close()
    {
        Modal.popByComponent(this.#_component);

        this.#_component = null;
    }
};
