import { ReduxSagaRequest } from '@hlibs/index';
import * as Requests from './requests';

class Account extends ReduxSagaRequest
{
    static create = (name, funcAsync = null, funcResult = null) => new Account(name, funcAsync, funcResult);

    get user() { return super.getState('user'); }

    signup(params)
    {
        super.request({ type: 'SignUp', params });
    }

    login(id, pw)
    {   
        super.request({ type: 'Login', params: { id, pw } });
    }

    tokenCheck()
    {
        super.request({ type: 'TokenCheck' });
    }

    logout()
    {
        super.request({ type: 'Logout' });
    }

    cleanLoginResponse()
    {
        super.set(null, 'Login');
    }

    cleanSignUpResponse()
    {
        super.set(null, 'SignUp');
    }
};

const reduxModule = Account.create("Account", async (payload) =>
{
    switch(payload.type)
    {
        case 'SignUp': return await Requests.SignUp(payload.params, reduxModule);
        case 'Login': return await Requests.Login(payload.params, reduxModule);
        case 'TokenCheck': return await Requests.TokenCheck(reduxModule);
        case 'Logout': return await Requests.Logout(reduxModule);
        default: return null;
    }
});

export default reduxModule;
