import React from 'react';
import { hs, Modal } from '@hlibs/index';
import { Box, Text, ButtonGroup, ButtonGroupExt } from '@hlibs/react-web/components';
import { colors } from '@app/index';

const strings = {
    ok: 'OK',
    cancel: 'Cancel',
    yes: 'Yes',
    no: 'No'
};

const defaultProps = 
{
    title: null,
    message: null,

    buttons: ButtonGroupExt.presetButtons.ok,

    callback: (index) => {},

    spacingX: 15,
    spacingY: 10,

    lineMaxButtonsCount: 2,
};

const defaultStyles = hs.mergeStyles(
    hs.size.minWidth(400), 
    hs.verticalLayout(), 
    hs.verticalAlign.center()  
);

function AlertDialog(props = defaultProps)
{
    const mergedProps = hs.mergeProps(defaultProps, props);

    const style = hs.mergeStyles(defaultStyles, mergedProps.style);

    const { close, title, message, callback } = mergedProps;

    const onCallback = (index, label) =>
    {
        const retVal = callback ? callback(index, label) : null;

        if(retVal == null || retVal == true)
        {
            close();
        }
    }

    return (
        <Box style={[hs.padding.leftRightAll(40), hs.verticalLayout(), hs.verticalAlign.center(), style]}>
            <Box style={[hs.size.fillParentWidth(), hs.verticalLayout(), hs.verticalAlign.center(), hs.radius.all(12), hs.backgroundColor(colors.white), hs.shadow(), hs.padding.topBottomAll(6), hs.padding.leftRightAll(20)]}>
                <Box style={[hs.size.fillParentWidth(), hs.verticalLayout(), hs.verticalAlign.center(), hs.padding.custom(25, 35, 25, 25)]}>
                    { title ? <Text style={[hs.margin.bottom(15), hs.text.bold(28, colors.black), hs.text.align.center()]}>{title}</Text> : null }
                    { message ? <Text style={[hs.text.regular(24, colors.black), hs.text.align.center()]}>{message}</Text> : null }
                </Box>
                <ButtonGroup {...mergedProps} onCallback={onCallback} style={[hs.padding.custom(25, 15, 25, 15)]}/>
            </Box>
        </Box>
    )
};

const makeButton = ButtonGroupExt.makeButton;

const presetButtons = {
    ok: [makeButton(strings.ok, colors.white)],
    okCancel: [makeButton(strings.cancel, '#eaeaea'), makeButton(strings.ok, colors.yellow)],
    yes: [makeButton(strings.yes)],
    yesNo: [makeButton(strings.no, '#eaeaea'), makeButton(strings.yes, colors.yellow)],
    deleteCancel: [makeButton('Cancel', colors.white), makeButton('Delete', colors.white, colors.deleteRed)],
};

export default class ModalController
{
    #_component = null;

    static create()
    {
        return new this();
    }

    static makeButton = makeButton;

    static presetButtons = presetButtons;

    modal(callback, message, buttons = presetButtons.ok, props = { preventBackButton: false })
    {
        const mergedProps = { callback, message, buttons, ...props };

        this.#_component = <AlertDialog {...mergedProps} close={() => this.close()} />;

        Modal.push(this.#_component);
    }

    close()
    {
        Modal.popByComponent(this.#_component);

        this.#_component = null;
    }
};
