import React, { useState, useRef, useEffect } from "react";
import DefaultLayout from '@layout/DefaultLayout';
import { hs, Assets, Modal } from "@hlibs/index";
import { Box, Image, InputBox, Spacing, Text, Check } from '@hlibs/react-web/components';
import { AlertDialog, QRCodeDialog } from "@modals/index";
import { colors } from '@app/index';
import { useSelector } from "react-redux";
import reduxVerification from '@redux/verification'
import reduxAccount from '@redux/account';

const BOX_MIN_WIDTH = 600;

const TableLayout = (props) =>
{
  const { title, icon, children, footer, style, titleBoxStyle } = props;

  return (
    <Box style={[hs.flex(), hs.flexLayout.vertical(), hs.border.all('solid', colors.border1, 1), style]}>
      <Box style={[hs.size.fillParentWidth(), hs.padding.custom(0, 20, 0, 20), hs.flexLayout.horizontal(), hs.flexAlign.horizontal.center(), hs.size.minWidth(BOX_MIN_WIDTH), titleBoxStyle]}>
        <Image src={Assets.fromImage.svg(icon)} style={[hs.size.all(44)]}/>
        <Spacing x={18} />
        <Text style={[hs.text.bold(29, colors.white)]}>{title}</Text>
      </Box>
      <Box style={[hs.size.fillParentWidth(), hs.flexLayout.vertical(), hs.size.minHeight(400)]}>
        <Box style={[hs.flex(), hs.padding.leftRightAll(53), hs.flexLayout.vertical(), hs.flexAlign.vertical.center()]}>
          {children}
        </Box>
        <Box style={[hs.size.fillParentWidth(), hs.size.height(76), hs.padding.leftRightAll(53), hs.flexLayout.horizontal(), hs.flexAlign.horizontal.leftMiddle(), hs.border.top('solid', colors.border1, 1)]}>
          {footer}
        </Box>
      </Box>

    </Box>
  );
};

const FooterText = ({ text }) => (
  <Box>
    <Text style={[hs.text.medium(17, '#3f3f3f')]}>{text}</Text>
  </Box>
);


const InputContainer = ({ buttonLabel, buttonTextColor, buttonBackgroundColor, onClick }) => 
{
  const inputRef = useRef();

  return (
    <Box style={[hs.size.height(54), hs.size.fillParentWidth(), hs.flexLayout.horizontal(), hs.outline(8, colors.border2, 2), hs.padding.left(10)]}>
      <InputBox ref={inputRef} onSubmit={onClick ? () => onClick(inputRef.current.getValue()) : null} inputStyle={[hs.text.regular(20, colors.black)]} style={[hs.flex(), hs.size.fillParentHeight(), hs.padding.right(10)]}/>
      <Box onClick={onClick ? () => onClick(inputRef.current.getValue()) : null} style={[hs.size.width(152), hs.backgroundColor(buttonBackgroundColor), hs.size.fillParentHeight(), hs.flexLayout.horizontal(), hs.flexAlign.horizontal.center(), hs.radius.right(4)]}>
        <Text style={[hs.text.medium(22, buttonTextColor)]}>{buttonLabel}</Text>
      </Box>
    </Box>
  );
}

const ViewLogin = () =>
{
  const settings = useSelector((state) => state.Config?.configs?.result);

  const allow1stOtac = settings?.ALLOW_1ST_OTAC == 'Y' ? true : false;

  const verification = useSelector((state) => state.Verification);

  useEffect(() =>
  {
    if(verification?.error?.message)
    {
      AlertDialog.create().modal(() => reduxVerification.reset(), verification?.error?.message, AlertDialog.presetButtons.ok);
    }

    else if(verification?.response?.result)
    {
      reduxAccount.tokenCheck();
    }
  }, [verification]);

  const onBtnQR = () =>
  {
    QRCodeDialog.create().modal(null, allow1stOtac);
  };

  const onBtnOtac1st = (otac) =>
  {
    reduxVerification.verify1st(otac);
  };

  return (
    <DefaultLayout style={[hs.backgroundColor(colors.bg1)]}>
      <Spacing y={94}/>
      <Text style={[hs.text.bold(37, colors.primary)]}>Log in to Online Banking</Text>
      <Spacing y={63}/>
      <Box style={[hs.flexLayout.horizontal(true)]}>
        <TableLayout icon={'login/icn_username'} title={'your username'} footer={<FooterText text={'Forgot your username? >'}/>} titleBoxStyle={[hs.radius.leftTop(8, colors.yellow)]} style={[hs.radius.left(8, colors.white)]}>
          <Text style={[hs.text.regular(21, '#3f3f3f'), hs.text.align.center()]}>Please enter<br/>your username eg IB1234567890 or John123</Text>
          <Spacing y={48}/>
          <InputContainer buttonBackgroundColor={colors.yellow} buttonTextColor={'#3f3f3f'} buttonLabel={'Continue'} onClick={() => {}}/>
          <Spacing y={21}/>
          <Check iconWidth={24} iconHeight={24} iconOn={Assets.fromImage.svg('login/icn_check_on')} iconOff={Assets.fromImage.svg('login/icn-check-off')}
            label={'Remember my username'} labelStyle={[hs.text.regular(19, '#3f3f3f')]} iconSpacing={10} defaultValue={true} style={[hs.size.height(54)]}/>
        </TableLayout>
        <TableLayout icon={'login/icn_swidch_code'} title={'swIDch Code'} titleBoxStyle={[hs.radius.rightTop(8, colors.yellow)]} style={[hs.radius.right(8, colors.white), hs.border.left('solid', colors.transparent, 0)]}>
          {
            allow1stOtac ? (
              <>
                <Text style={[hs.text.regular(21, '#3f3f3f'), hs.text.align.center()]}>Please enter<br/>your swIDch Code</Text>
                <Spacing y={48}/>
                <InputContainer buttonBackgroundColor={'#3f3f3f'} buttonTextColor={colors.yellow} buttonLabel={'LOGIN'} onClick={(otac) => { onBtnOtac1st(otac); }}/>
                <Spacing y={21}/>
              </>
            ) : null
          }
          <Box style={[hs.size.height(54), hs.size.fillParentWidth(), hs.flexLayout.horizontal(), hs.padding.left(10)]}>
            <Spacing flex={1}/>
            <Box onClick={onBtnQR} style={[hs.size.width(152), hs.backgroundColor('#3f3f3f'), hs.size.fillParentHeight(), hs.flexLayout.horizontal(), hs.flexAlign.horizontal.center(), hs.radius.all(4)]}>
              <Text style={[hs.text.medium(22, colors.yellow)]}>{'QR LOGIN'}</Text>
            </Box>
          </Box>
        </TableLayout>
      </Box>
    </DefaultLayout>
  );
};

export default ViewLogin;