import { ReduxSagaRequest, Utils, Debug, Request } from '@hlibs/index';
import Language from '@app/language';

class Config extends ReduxSagaRequest
{
    static create = (name, funcAsync = null, funcResult = null) => new Config(name, funcAsync, funcResult);

    getSettings()
    {
        super.request({ type: 'get' });
    }

    set(key, value)
    {
        super.request({ type: 'set', key, value });
    }
};

const reduxModule = Config.create("Config", async (payload) =>
{
    switch(payload.type)
    {
        case 'get': return await get(payload);
        case 'set': return await set(payload);
        default: return null;
    }
});

const get = async (payload) =>
{
    const stateKey = 'configs';

    try
    {
        const { data } = await Request.get('/config/websettings', payload);

        if(data?.error)
        {
            return ReduxSagaRequest.makeResult.failure(data, stateKey);
        }

        else if(Utils.checkKeyExists(data, ['result']))
        {   
            return ReduxSagaRequest.makeResult.success(data, stateKey);
        }    
    }
    catch(e)
    {
        if(process.env.MODE !== 'RELEASE') Debug.exception(e);
    }

    return ReduxSagaRequest.makeResult.failureWithMessage(Language.Server.err1, stateKey);
}; 

const set = async (payload) =>
{
    const stateKey = 'set';

    if(Utils.checkNullOrEmpty(payload, payload.key, payload.value)) return ReduxSagaRequest.makeResult.failureWithMessage(Language.Server.err3, stateKey);

    try
    {
        const { data } = await Request.post('/config/set', payload);

        if(data?.error)
        {
            return ReduxSagaRequest.makeResult.failure(data, stateKey);
        }

        else if(Utils.checkKeyExists(data, ['result']))
        {   
            return ReduxSagaRequest.makeResult.success(data, stateKey);
        }    
    }
    catch(e)
    {
        if(process.env.MODE !== 'RELEASE') Debug.exception(e);
    }

    return ReduxSagaRequest.makeResult.failureWithMessage(Language.Server.err1, stateKey);
}; 

export default reduxModule;