import Redux from '@hlibs/react/redux';
import Account from './account';
import Socket from './socket';
import Verification from './verification';
import User from './user';
import Admin from './admin';
import KYC from './kyc';
import Config from './config';

// 커스텀 혹은 외부 reducer 가 있으면 여기에 추가
const reducers = {

};

// 커스텀 혹은 외부 saga 가 있으면 여기에 추가
const sagas = {

};

// hlibs/redux 의 모듈들 혹은 스타일에 맞게 만든 redux / redux-saga 모듈(대표적으로 redux/account)의 경우 여기에 추가.
const reduxModules = 
{
    Account,
    Socket,
    Verification,
    User,
    Admin,
    KYC,
    Config
};

export const createStore = () =>
{
    return Redux.createStore(reduxModules, reducers, sagas);
};

export default reduxModules;