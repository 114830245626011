const ImageDirName = 'img';

class Assets
{
    static fromImage =
    {
        custom: (filename, ext = null) => this.load(ImageDirName + "/" + filename + (ext ? "." + ext : "")),
        png: (filename) => this.fromImage.custom(filename, "png"),
        jpg: (filename) => this.fromImage.custom(filename, "jpg"),
        svg: (filename) => this.fromImage.custom(filename, "svg"),
    }

    static load(assetPath)
    {
        return assetPath ? require('@assets/' + assetPath) : null;
    }
}

export default Assets;
