import React, { useEffect } from "react";
import * as hs from "@hlibs/react/hstyles"; 
import { Box, ImageButton, Spacing, Text } from '@hlibs/react-web/components';
import { Assets } from "@hlibs/index";
import { colors } from '@app/index';
import { Header } from "@hlibs/react-web/layouts";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import reduxAccount from '@redux/account';

const TextButton = (props) =>
{
  const { label, onClick } = props;

  return (
    <Box onClick={onClick} style={[hs.size.custom(114, 45), hs.flexLayout.horizontal(), hs.flexAlign.horizontal.center(), props.style]}>
      <Text style={[hs.text.medium(21, colors.white)]}>{label}</Text>
    </Box>
  );
};

const YellowButton = (props) =>
{
  const { label, onClick } = props;

  return (
    <Box onClick={onClick} style={[hs.size.custom(114, 45), hs.flexLayout.horizontal(), hs.flexAlign.horizontal.center(), hs.radius.all(23, colors.yellow), props.style]}>
      <Text style={[hs.text.bold(21, colors.header)]}>{label}</Text>
    </Box>
  );
};

const WhiteButton = (props) =>
{
  const { label, onClick } = props;

  return (
    <Box onClick={onClick} style={[hs.size.custom(114, 45), hs.flexLayout.horizontal(), hs.flexAlign.horizontal.center(), hs.radius.all(23, colors.white), props.style]}>
      <Text style={[hs.text.bold(21, colors.yellow)]}>{label}</Text>
    </Box>
  );
};

const Component = () =>
{
  const navigate = useNavigate();

  const location = useLocation();

  const user = useSelector((state) => state.Account.user);

  useEffect(() => 
  {
    reduxAccount.tokenCheck();
  }, []);

  useEffect(() =>
  {
    if(!user)
    {
      if(location.pathname == '/main')
      {
        navigate('/login', { replace: true });
      }
    }

    else 
    {
      if(location.pathname == '/login' || location.pathname == '/')
      {
        navigate('/main', { replace: true });
      }
    }    
  }, [user]);

  const onBtnLogin = () =>
  {
    navigate('/login', { replace: true });
  };

  const onBtnLogout = () =>
  {
    reduxAccount.logout();
  };

  const onBtnAdmin = () =>
  {
    navigate('/admin', { replace: true });
  };

  const onBtnMain = () =>
  {
    navigate('/', { replace: true });
  };

  const logoComponent = <ImageButton src={Assets.fromImage.svg('logo_swidch')} width={153} height={39} onClick={onBtnMain}/>;

  return (
    <Header bgColor={colors.header} minHeight={112} paddingLeftRightFlex={0.2} logo={logoComponent} containerStyle={[]}>
      <TextButton label="Bank" />
      <Spacing flex={0.05}/>
      <TextButton label="Borrow" />
      <Spacing flex={0.05}/>
      <TextButton label="Credit card" />
      <Spacing flex={0.05}/>
      <TextButton label="Invest" />
      <Spacing flex={0.05}/>
      <TextButton label="Insure" />
      <Spacing flex={0.05}/>
      {user ? <WhiteButton label="Log out" onClick={onBtnLogout} /> : <YellowButton label="Log in" onClick={onBtnLogin} />}
      <Spacing flex={0.05}/>
      <TextButton label="Admin" onClick={onBtnAdmin} />      
    </Header>
  );
};

export default Component;