import { ReduxSagaRequest, Request, Debug } from '@hlibs/index';
import Language from '@app/language';

const OTAC_COMPANY_ID = 'ssenstone';
const OTAC_SYSTEM_ID = 8;

class Verification extends ReduxSagaRequest
{
    static create = (name, funcAsync = null, funcResult = null) => new Verification(name, funcAsync, funcResult);

    // 1차 검증
    verify1st(otacCode, userId)
    {
        if(super.getState('processing') == true) return;

        super.request({ type: 'verify1st', otacCode, userId });
    }

    // 2차 검증
    verify2nd(otacCode, userId, cardId)
    {
        if(super.getState('processing') == true) return;

        super.request({ type: 'verify2nd', otacCode, cardId, userId });
    }

    reset()
    {
        super.set(null, 'error');
        super.set(null, 'response');
    }
};

const reduxModule = Verification.create("Verification", async (payload) =>
{
    switch(payload.type)
    {
        case 'verify1st': return await verify1st(payload);
        case 'verify2nd': return await verify2nd(payload);
        default: return null;
    }
});

const verify1st = async (payload) =>
{
    const { otacCode, userId } = payload;

    if(!otacCode) return ReduxSagaRequest.makeResult.failure({ message: Language.OTAC.err1 });

    try
    {
        const params = {
            otac: otacCode,
            systemId: OTAC_SYSTEM_ID,
            companyId: OTAC_COMPANY_ID,
            userId
        };
    
        const { data } = await Request.post('/verification/verify1st', params);

        if(data?.result == 'SUCCESS')
        {
            localStorage.setItem("lastVerifiedOtac", "0");

            return ReduxSagaRequest.makeResult.success({ result: true, message: Language.OTAC.msg1 });
        }
        else if(data?.result == 'ERROR')
        {
            let errorMessage = "";

            switch(data?.errorCode)
            {
                case 'O900': errorMessage = Language.OTAC.err2; break;
                default: break;
            }

            if(errorMessage) return ReduxSagaRequest.makeResult.failure({ message: errorMessage });
        }
    }
    catch(e)
    {
        if(process.env.MODE !== 'RELEASE') Debug.exception(e);
    }

    return ReduxSagaRequest.makeResult.failure({ message: Language.OTAC.err3 });
};

const verify2nd = async (payload) =>
{
    const { otacCode, cardId, userId } = payload;

    if(!otacCode) return ReduxSagaRequest.makeResult.failure({ message: Language.OTAC.err1 });

    try
    {
        const params = {
            otac: otacCode,
            systemId: OTAC_SYSTEM_ID,
            companyId: OTAC_COMPANY_ID,
            cardId,
            userId
        };

        const { data } = await Request.post('/verification/verify2nd', params);
    
        if(data?.result == 'SUCCESS')
        {
            localStorage.setItem("lastVerifiedOtac", "1");

            return ReduxSagaRequest.makeResult.success({ result: true, message: Language.OTAC.msg1 });
        }
        else if(data?.result == 'ERROR')
        {
            let errorMessage = "";

            switch(data?.errorCode)
            {
                case 'O900': errorMessage = Language.OTAC.err2; break;
                default: break;
            }

            if(errorMessage) return ReduxSagaRequest.makeResult.failure({ message: errorMessage });
        }
    }
    catch(e)
    {
        if(process.env.MODE !== 'RELEASE') Debug.exception(e);
    }

    return ReduxSagaRequest.makeResult.failure({ message: Language.OTAC.err3 });
};

export default reduxModule;
