import Image from './Image';
import Box from './Box';
import * as hs from '@hlibs/react/hstyles';
import PropsDivider from '@hlibs/react/props-divider';

const defaultProps = 
{
    width: null,
    height: null,
    src: null,
};

const defaultStyles = {
  
};

const defaultImageStyles = {

};

const ImageButton = (props = defaultProps) =>
{
    const mergedProps = hs.mergeProps(defaultProps, props);

    const styles = hs.mergeStyles(defaultStyles, mergedProps.style);

    if(mergedProps.source && !mergedProps.src)
    {
        mergedProps.src = mergedProps.source;

        delete mergedProps.source;
    }

    // Image props 분리
    const imageProps = PropsDivider.image(mergedProps, ['imageStyle']);

    const imageStyle = hs.mergeStyles(defaultImageStyles, imageProps.imageStyle);

    return (
        <Box {...mergedProps} style={styles}>
            <Image {...imageProps} style={imageStyle}/>
        </Box>
    )
};

export default ImageButton;