import * as Debug from '@hlibs/debug';
//import { useSelector, shallowEqual } from 'react-redux';

// Request (Run) / Success / Failure 3가지 상태(액션) 대응 => 기본 비동기 처리
// ex) const ReduxThunkRequest = ReduxThunkRequest.create(name);
// 사용상 편의를 위해 SetExtra 상태 추가 => extra 에 임의에 데이터 set 가능
export default class ReduxThunkRequest
{    
    static create = (name, funcAsync = null, funcResult = null) => new ReduxThunkRequest(name, funcAsync, funcResult);

    static makeResult = {
        success: (data, key = 'response') => { return { success: true, data, key } },
        successWithMessage: (message, key = 'response') => { return { success: true, data: { message }, key } },
        failure: (error, key = 'error', autoMakeErrorKey = true) => 
        { 
            const errorCur = autoMakeErrorKey ? (error != null && typeof error == 'object' && 'error' in error ? error.error : error) : error;

            return { success: false, error: autoMakeErrorKey == false ? error : key == 'error' ? errorCur : { error: errorCur }, key } 
        },
        failureWithMessage: (message, key = 'error', autoMakeErrorKey = true) => { return { success: false, error: autoMakeErrorKey == false ? { message } : key == 'error' ? { message } : { error: { message } }, key } },
    };

    // action request : 생성자 혹은 setDefaultFuncAsync() 로 설정한 funcAsync 함수 사용
    request(payload, forceRequest = false)
    {
        if(!this.#_store)
        {
            Debug.error(this.constructor.name + '.setStore 로 store 를 지정해주세요.');
        }

        if(forceRequest === false && this.isProcessing() === true) return;

        const _funcAsync = this.#_funcAsync;

        if (_funcAsync == null || typeof _funcAsync !== 'function') 
        {
            Debug.error('ReduxThunkRequest.request(payload) : funcAsync must be a function => async (payload, action) => { return ReduxSagaRequest.makeResult.success(data); };');
        }

        this.#_store.dispatch(this.#_thunkFunc(payload, _funcAsync, this.#_actionTypes.Request));
    };

    // action run : 바로 인자로 넘긴 비동기 함수 호출
    // funcAsync : run 액션에 대한 실제 처리 함수 (비동기)
    // ex) => async (payload, action, reduxThunkRequest) => { return ReduxSagaRequest.makeResult.success(data); };
    run(funcAsync, payload, forceRequest = false)
    {
        if(!this.#_store)
        {
            Debug.error(this.constructor.name + '.setStore 로 store 를 지정해주세요.');
        }

        if(forceRequest === false && this.isProcessing() === true) return;

        const _funcAsync = funcAsync;

        if (_funcAsync == null || typeof _funcAsync !== 'function') 
        {
            Debug.error('ReduxThunkRequest.run(funcAsync, payload) : funcAsync must be a function => async (payload, action) => { return ReduxSagaRequest.makeResult.success(data); };');
        }

        this.#_store.dispatch(this.#_thunkFunc(payload, _funcAsync, this.#_actionTypes.Run));
    };

    // extra 에 key / value 데이터 저장
    setExtra(key, data)
    {
        if(!this.#_store)
        {
            Debug.error(this.constructor.name + '.setStore 로 store 를 지정해주세요.');

            return;
        }
        
        if(key == null)
        {
            Debug.error(this.constructor.name + '.setExtra => key 값을 입력해주세요');
        }

        const state = this.getState();

        const extra = state.extra ? state.extra : {};

        if(data != null)
        {
            extra[key] = data;
        }

        else
        {
            delete extra[key];
        }

        this.#_store.dispatch(this.#_actions.SetExtra(extra));
    }

    // set state
    set(data, key = 'data')
    {
        if(!this.#_store)
        {
            Debug.error(this.constructor.name + '.setStore 로 store 를 지정해주세요.');
        }

        this.#_store.dispatch(this.#_actions.Set(data, key));
    };

    // set state multiple => object type 으로 전달
    setMultiple(dataObject)
    {
        if(!this.#_store)
        {
            Debug.error(this.constructor.name + '.setStore 로 store 를 지정해주세요.');
        }

        this.#_store.dispatch(this.#_actions.SetMultiple(dataObject));
    };

    // states 초기화 : preserveExtra => 초기화시 extra 보존 여부
    reset(preserveExtra = false)
    {
        this.#_store.dispatch(this.#_actions.Reset(preserveExtra));
    }

    getExtra(key)
    {
        const state = this.getState();

        return state == null ? null : state.extra && typeof state.extra == 'object' && key in state.extra ? state.extra[key] : null;
    }

    getData()
    {
        const state = this.getState();

        return state ? state.data : null;
    }

    getError()
    {
        const state = this.getState();

        return state ? state.error : null;
    }

    isError()
    {
        return this.getError() ? true : false;
    }

    // request or run 처리중인지 확인
    isProcessing() 
    { 
        const state = this.getState(); 

        return state ? state.processing : false;
    };

    getState(key = null) 
    { 
        const state = this.#_store ? this.#_store.getState() : null;

        return !state ? null : key ? state[this.#_name][key] : state[this.#_name];
    };

    dispatch(...args)
    {
        if(this.#_store)
        {
            this.#_store.dispatch(...args);
        }

        else
        {
            Debug.error(this.constructor.name + '.setStore 로 store 를 지정해주세요.');
        }
    };

/*
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // rule 에 의해 useSelector 를 포함한 hook 함수들은 각 리액트 컴포넌트에서만 사용할 것
    useSelector = () =>
    {
        return useSelector((state) => state[this.#_name], shallowEqual);
    };
*/

    // funcAsync : Request 액션에 대한 실제 처리 함수 (비동기)
    // ex) => async (payload, action) => { return ReduxSagaRequest.makeResult.success(data); };
    // 생성자 혹은 setDefaultFuncAsync() 로 지정 가능
    // funcResult : Request 액션 처리 후 결과값 콜백. 처리 중 exception 시 전달 x.
    // ex) => (result) => {}
    constructor(name, funcAsync = null, funcResult = null)
    {
        if (typeof name !== 'string') 
        {
            Debug.error('name must be a string');
        }
    
        const nameUpperCase = name.toUpperCase();

        const initialState = { processing: false, data: null, error: null, extra: null };

        const actionTypes = {
            Run: nameUpperCase + '/RUN',
            Request: nameUpperCase + '/REQUEST',
            Success: nameUpperCase + '/SUCCESS',
            Failure: nameUpperCase + '/FAILURE',
            Set: nameUpperCase + '/SET',
            SetMultiple: nameUpperCase + '/SET_MULTIPLE',
            SetExtra: nameUpperCase + '/SET_EXTRA',
            Reset: nameUpperCase + '/RESET',
        };    

        const actions = {
            Run: () => { return { type: actionTypes.Run }; },
            Request: () => { return { type: actionTypes.Request }; },
            Success: (data, key = 'response') => { return { type: actionTypes.Success, data, key }; },
            Failure: (error, key = 'error') => { return { type: actionTypes.Failure, error, key }; },
            Set: (data, key) => { return { type: actionTypes.Set, data, key }; },
            SetMultiple: (dataObject) => { return { type: actionTypes.SetMultiple, dataObject }; },
            SetExtra: (extra) => { return { type: actionTypes.SetExtra, extra }; },
            Reset: (preserveExtra = false) => { return { type: actionTypes.Reset, preserveExtra }; },
        };

        const reducer = (state = initialState, action) => 
        {
            switch (action.type) 
            {
                case actionTypes.Run:
                    return {
                        ...state,
                        processing: true,
                    };    
                case actionTypes.Request:
                    return {
                        ...state,
                        processing: true,
                        error: null,
                    };    
                case actionTypes.Success:
                    return {
                        ...state,
                        processing: false,
                        [action.key]: action.data,
                    };    
                case actionTypes.Failure:
                    return {
                        ...state,
                        processing: false,
                        [action.key]: action.error,
                    };
                case actionTypes.Set:
                    return action.key ? {
                        ...state,
                        [action.key] : action.data
                    } : {
                        ...state,
                        ...action.data
                    };
                case actionTypes.SetMultiple:
                    return {
                        ...state,
                        ...action.dataObject
                    };
                case actionTypes.Reset:
                    return {
                        ...initialState,
                        extra : action.preserveExtra ? state.extra : null
                    };
                case actionTypes.SetExtra:
                    return {
                        ...state,
                        extra: action.extra
                    };            
            	default:
                    return state;                    
            };
        };

        this.#_name = name;
        this.#_actionTypes = actionTypes;
        this.#_actions = actions;
        this.#_reducer = reducer;
        this.#_funcAsync = funcAsync;
        this.#_onFuncResult = funcResult;

        return this;
    }

    setDefaultFuncAsync(funcAsync)
    {
        this.#_funcAsync = funcAsync;
    }

    // 비동기 작업 처리 해 줄 thunk 함수
    #_thunkFunc = (payload, funcAsync, actionType) => async (dispatch, getState) => 
    {
        dispatch(actionType === this.#_actionTypes.Request ? this.#_actions.Request() : this.#_actions.Run());

        try
        {
            if(funcAsync)
            {
                let result = await funcAsync(payload, actionType, this);

                // makeResult 사용시
                if(result && typeof result == 'object' && 'success' in result && result.key)
                {
                    if(result.success) dispatch(this.#_actions.Success(result.data, result.key));
                    else dispatch(this.#_actions.Failure(result.error, result.key));
                }

                else
                {
                    dispatch(this.#_actions.Success(result));
                }
    
                if(this.#_onFuncResult)
                {
                    this.#_onFuncResult(result);
                }
            }
    
            else
            {
                dispatch(this.#_actions.Failure());
            }    
        }
        catch(e)
        {
            Debug.exception(e);

            dispatch(this.#_actions.Failure());
        }    
    };

    setStore(store) { this.#_store = store; };

    #_store = null;
    #_name = "";

    #_actions = null;
    #_reducer = null;    
    #_actionTypes = null;

    #_funcAsync = null;
    #_onFuncResult

    get name() { return this.#_name; };
    get actions() { return this.#_actions; };
    get actionTypes() { return this.#_actionTypes; };
    get reducer() { return this.#_reducer; };
    get thunk() { return true };
    get store() { return this.#_store; };
};