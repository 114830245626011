import React from "react";
import * as hs from "@hlibs/react/hstyles"; 
import { Box } from '@hlibs/react-web/components';
import BaseLayout from "@hlibs/react-web/layouts/BaseLayout";

const defaultProps = 
{
  maxWidth: 1600,
  maxHeight: null,

  minWidth: 600,
  minHeight: null,

};

const defaultStyles = {
  
};

const HeaderFooterLayout = (props) =>
{
  const mergedProps = hs.mergeProps(defaultProps, props);

  const styles = hs.mergeStyles(defaultStyles, mergedProps.style);

  const { children, header, footer } = mergedProps;

  return (
    <BaseLayout {...mergedProps} style={[styles]}>
      {header}
      <Box style={[hs.size.fillParentWidth(), hs.flex(), hs.flexLayout.vertical(), hs.flexAlign.vertical.centerTop()]}>
        {children}
      </Box>
      {footer}
    </BaseLayout>
  );
};

export default HeaderFooterLayout;