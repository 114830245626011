import React from "react";
import * as hs from "@hlibs/react/hstyles"; 
import { ImageButton, Image, Spacing } from '@hlibs/react-web/components';
import { Assets } from "@hlibs/index";
import { Footer } from "@hlibs/react-web/layouts";
import { useWindowSize } from "@hlibs/react-web/hook";

const Component = () =>
{
  const windowsSize = useWindowSize(0, 1000);

  const align = windowsSize.width <= 836 ? hs.flexAlign.horizontal.center() : hs.flexAlign.horizontal.rightMiddle();

  return (
    <Footer minHeight={63} paddingLeftRightFlex={0.2} containerStyles={[hs.size.fillParentWidth(), hs.flexLayout.horizontal(), hs.padding.leftRightAll(20), align]}>
      <ImageButton src={Assets.fromImage.png('footer/btn_help_support')} width={155} height={26} />
      <Spacing x={31}/>
      <Image src={Assets.fromImage.png('footer/line_white')} width={1} height={15}/>
      <Spacing x={31}/>
      <ImageButton src={Assets.fromImage.png('footer/btn_find_a_branch')} width={137} height={26} />
    </Footer>
  );
};

export default Component;