import { useSelector, shallowEqual } from 'react-redux';
import * as hs from '@hlibs/react/hstyles';
import redux from './redux';
// import AppHandlers from '@hlibs/react-native/app/AppHandlers';
// import { useEffect } from 'react';

import Background from '@hlibs/react-web/components/modal/Background';
import LoadingComponent, { defaultProps as LoadingProps } from '@hlibs/react-web/components/modal/Loading';
import ToastComponent, { defaultProps as ToastProps } from '@hlibs/react-web/components/modal//Toast';
import * as Utils from '@hlibs//utils';

const modalComponentStack = [];

export const ModalComponents = ({ backgroundColor = '#00000090' }) =>
{
    const { loading = false, loadingProps = {}, toastProps = {} } = useSelector((state) => state[redux.name], shallowEqual);

    const toast = toastProps?.endTime && Utils.DateUtil.now() < toastProps.endTime ? true : false;

    const componentCur = modalComponentStack.length > 0 ? modalComponentStack[modalComponentStack.length - 1] : null;

    // useEffect(() =>
    // {
    //     const preventBackButton = loading || componentCur?.props?.preventBackButton || false;

    //     const removeHandler = AppHandlers.OnBack.add(() => { return preventBackButton; });

    //     return () => { if(removeHandler) removeHandler(); };
    // }, [loading, componentCur]);

    return (
        <>
        {
            componentCur || loading ? (
                <Background style={[hs.fixed.leftTop(), hs.size.fillParent(), hs.backgroundColor(backgroundColor), hs.verticalLayout(), hs.verticalAlign.center()]}> 
                    {componentCur}
                    {loading ? <LoadingComponent {...loadingProps}/> : null}
                </Background>
            ) : null
        }
        {
            toast ? (
                <ToastComponent {...toastProps} hide={() => Toast.hide()} />
            ) : null
        }
        </>
    )
};

export const Loading = class
{
    static show(props = LoadingProps) { redux.setLoading(true, props); }

    static hide() { redux.setLoading(false); }
};

export const Toast = class
{
    static show(message, durationSec = 3, props = ToastProps) { redux.showToast(message, durationSec, props); }

    static hide() { redux.hideToast(); }
};

class Modal
{
    static push(component)
    {
        modalComponentStack.push(component);

        redux.updateComponent();

        return () =>
        {
            Modal.popByComponent(component);
        };
    }

    static pop()
    {
        if(modalComponentStack.length > 0)
        {
            modalComponentStack.pop();

            redux.updateComponent();
            
            return true;
        }

        return false;
    }

    static get length()
    {
        return modalComponentStack.length;
    }

    static popByName(componentName, popAll = false)
    {
        if(!componentName) return;
        
        let popCount = 0;

        for(let i = modalComponentStack.length - 1; i >= 0; i--)
        {
            const componentCur = modalComponentStack[i];

            if(componentCur?.type?.name == componentName)
            {
                modalComponentStack.splice(i, 1);

                popCount++;

                if(popAll == false) break;
            }
        }

        if(popCount > 0)
        {
            redux.updateComponent();
        }
    }

    static popByComponent(component)
    {
        if(!component) return;
        
        for(let i = modalComponentStack.length - 1; i >= 0; i--)
        {
            const componentCur = modalComponentStack[i];

            if(componentCur == component)
            {
                modalComponentStack.splice(i, 1);

                redux.updateComponent();

                return;
            }
        }
    }

    static popByIndex(index)
    {
        if(index < 0 || index >= modalComponentStack.length) return;

        modalComponentStack.splice(index, 1);

        redux.updateComponent();
    }
    
    static popAll()
    {
        modalComponentStack.splice(0, modalComponentStack.length);

        redux.updateComponent();
    }
};

export default Modal;